import React from 'react';
import { Button, Grid } from '@mui/material';
import './cookiesMessage.css';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAllowCookies } from '../../../redux/reducers/registrantSlice';

export default function CookiesMessage() {

  const { allowCookies } = useAppSelector((state: any) => state.registrant);
  const dispatch = useAppDispatch();

  return (
    <>
      { !allowCookies ? 
        <div className="cookies-message-container noprint">
          <Grid container alignItems="center" alignContent="flex-end">
            <Grid item md={7} xs={12} className="cookies-message-grid-item"  p={2}>
              We use cookies to improve and customize your experience if you continue to browse. Is this OK?
            </Grid>
            <Grid item md={3} xs={12} className="cookies-message-grid-item" p={1}>
              <Button id="cookies-message-accept-cookies" style={{ textTransform: 'none', color: 'white' }} variant="outlined"  onClick={(e: any) => dispatch(setAllowCookies(true))}>Yes, Accept Cookies</Button>
            </Grid>
          </Grid>
        </div>
        : null
      }
    </>
  )
}
