import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import store from './redux/store';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

export default function MyApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<App />} />
          {/*
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
          */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider
      domain={ window.location.hostname === 'localhost' ? 'dev-0fkxkx5f.us.auth0.com' : 'tfletservices.us.auth0.com' }
      clientId={ window.location.hostname === 'localhost' ? 'ryCXFBlgFrM4OS39ed5ydzsKhSO26XZ5' : 'tNDuhs11bu33pqXZNMjDw3AK4EZGdNFj' }
      authorizationParams={{ redirect_uri: window.location.origin }}
      >
        <Provider store={store}>
          <App />
        </Provider>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
