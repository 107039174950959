import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import {
  Assignment as AssignmentIcon,
  Celebration as CelebrationIcon,
  FileDownload as FileDownLoadIcon,
  Group as GroupIcon,
  GroupWork as GroupWorkIcon,
  PendingActions as PendingActionsIcon,
  Task as TaskIcon,
  VolunteerActivism as VolunteerActivismIcon
} from '@mui/icons-material';
import './welcomeCard.css';

interface IWelcomeCard {
  title: string,
  shortDescription: string,
  detailedDescription: string[]
}

export default function WelcomeCard(props: IWelcomeCard) {

  const [ showMore, setShowMore ] = useState<boolean>(false);

  return (
    <>
      <Grid key={'welcome-card-' + props.title.replace(/ /g, '_')} item className="welcome-card-item" md={5} sm={12}>
        { props.title === 'Class' ?
          <h2><GroupIcon style={{ fontSize: 24 }} /> {props.title}</h2>
          : null
        }
        { props.title === 'Ad-Hoc Class' ?
          <h2><GroupWorkIcon style={{ fontSize: 24 }} /> {props.title}</h2>
          : null
        }
        { props.title === 'File Download' ?
          <h2><FileDownLoadIcon style={{ fontSize: 24 }} /> {props.title}</h2>
          : null
        }
        { props.title === 'Enter Trials' ?
          <h2><PendingActionsIcon style={{ fontSize: 24 }} /> {props.title}</h2>
          : null
        }
        { props.title === 'Show-N-Go' ?
          <h2><TaskIcon style={{ fontSize: 24 }} /> {props.title}</h2>
          : null
        }
        { props.title === 'Private Auction' ?
          <h2><CelebrationIcon style={{ fontSize: 24 }} /> {props.title}</h2>
          : null
        }
        { props.title === 'Volunteers' ?
          <h2><VolunteerActivismIcon style={{ fontSize: 24 }} /> {props.title}</h2>
          : null
        }
        { props.title === 'Trial Secretary service' ?
          <h2><AssignmentIcon style={{ fontSize: 24 }} /> {props.title}</h2>
          : null
        }
        <div>{props.shortDescription}</div>
        <div className="welcome-card-bottom-buffer"></div>
        <Button
              id="welcom-card-show-more"
              style={{textTransform: 'none', color: 'white'}}
              onClick={(e: any) => setShowMore(!showMore)}
              color="secondary">
              { showMore ? 'less' : 'more' }
        </Button>
        { showMore ? 
          <>
            { props.detailedDescription.map((line: string, index: number) => 
              <div key={'line-' + index}>{line}</div>
            )}
          </>
          : null
        }
      </Grid>
    </>
  )
}
