import React from 'react';
import './entryFormView.css';
import EntryFormSummary from '../../molecules/entryFormSummary/entryFormSummary';
import EntryFormSteps from '../../atoms/entryFormSteps/entryFormSteps';
import EntryFormReview from '../../molecules/entryFormReview/entryFormReview';
import EntryFormPayment from '../../molecules/entryFormPayment/entryFormPayment';
import EntryFormDone from '../../molecules/entryFormDone/entryFormDone';
import { useAppSelector } from '../../../redux/hooks';
import { Grid } from '@mui/material';

export default function EntryFormView() {

  const { stepIndex } = useAppSelector((state: any) => state.entryForms);

  return (
    <Grid container alignItems="center" alignContent="center" justifyContent="center"className='entry-form-view'>
      <EntryFormSteps />

      { (stepIndex + 1) === 1 ?
        <EntryFormSummary />
        : null
      }
      
      { (stepIndex + 1) === 2 ?
        <EntryFormReview />
        : null
      }
      
      { (stepIndex + 1) === 3 ?
        <EntryFormPayment />
        : null
      }

      { (stepIndex + 1) === 4 ?
        <EntryFormDone />
        : null
      }
    </Grid>
  )
}
