import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataInfo } from "../../models/DataInfo";
import { DataRecord } from "../../models/DataRecord";

const initialState: DataRecord = {
  _id: '',
  _rev: '',
  data: [] as DataInfo[]
}

export const eventDataSlice = createSlice({
  name: "eventData",
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state._id = action.payload;
    },
    setRev: (state, action: PayloadAction<string>) => {
      state._rev = action.payload;
    },
    setData: (state, action: PayloadAction<DataInfo[]>) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { 
  setId,
  setRev,
  setData,
 } = eventDataSlice.actions;

export default eventDataSlice.reducer;
