import React from 'react';
import { useAppSelector } from '../../../redux/hooks';
import './secretaryEvents.css';
import { SecretaryEventConfig } from '../../../models/SecretaryEventConfig';
import moment from 'moment-timezone';
import {AppBar, Container, Grid } from '@mui/material';

interface ISecretaryEvents {
  eventId: string
}

export default function SecretaryEvents(props: ISecretaryEvents) {

  const { secretaryEvents } = useAppSelector((state: any) => state.eventConfig);

  const getFriendlyDates = (dates: string[]) => {
    let result = "";
    let year = moment(new Date(dates[0]).toISOString()).format('YYYY');
    dates.forEach((e, i) => {
      result += moment(new Date(e).toISOString()).format('MMM Do');
      if (i < dates.length -1) {
        result += ' & ';
      }
    })
    return result + ", " + year;
  }
  return (
    <div>
      <AppBar position="sticky" className="noprint">
        <Container maxWidth="xl"  className="app-secretary-events-header">
          <div className="app-secretary-events-header-text">Upcoming Events</div>
          { secretaryEvents.length === 0 ?
            <div>No Events upcoming...check back later</div>
            : <></>
          }
        </Container>
      </AppBar>
      < Grid container justifyContent="center" spacing={2}>
      { secretaryEvents.map((secretaryEvent: SecretaryEventConfig, secretaryEventIndex: number) =>
          <Grid item md={11} xs={11} key={`secretaryEvent-${secretaryEventIndex}`}>
              <Grid container className="app-secretary-events-card" key={`secretaryEvent-card-${secretaryEventIndex}`}>
                <Grid item md={12} xs={12}>
                  <div className="app-secretary-events-card-line-bold app-secretary-events-card-line app-secretary-events-card-first-line">{secretaryEvent.clubName}</div>
                  <div className="app-secretary-events-card-line app-secretary-events-card-line-event-type">{secretaryEvent.eventType} Trials</div>
                  <div className="app-secretary-events-card-line app-secretary-events-margin-botttom-10">{getFriendlyDates(secretaryEvent.dates)}</div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="app-secretary-events-card-line">Opens: {moment(secretaryEvent.openingDateTime).format('MMM DD, YYYY h:mma')}</div>
                  <div className="app-secretary-events-card-line app-secretary-events-margin-botttom-10">Closes: {moment(secretaryEvent.closingDateTime).format('MMM DD, YYYY h:mma')}</div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="app-secretary-events-card-line app-secretary-events-margin-botttom-10">
                    { secretaryEvent.judges.length === 0 ?
                      <div>Pending</div>
                      : 
                      <>
                        <div>Judge{ secretaryEvent.judges.length > 1 ? 's' : ''}</div>
                        { secretaryEvent.judges.map((judge: string, judgeIndex: number) =>
                          <div key={`secretaryEvent-card-${secretaryEventIndex}-judge-${judgeIndex}`}>{judge}</div>
                        )}
                      </>
                    }
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  { secretaryEvent.files.length === 0 ?
                    <div className="app-secretary-events-card-line app-secretary-events-margin-botttom-10">Premium Coming Soon</div>
                    : null
                  }
                  { secretaryEvent.files.map((file: any, fileIndex: number) =>
                    <div key={`secretaryEvent-card-${secretaryEventIndex}-file-${fileIndex}`} className="app-secretary-events-card-line app-secretary-events-margin-botttom-10">
                      <a href={file.url} download target="blank">{file.label}</a>
                    </div>
                  )}
                  { secretaryEvent.links.map((link: any, linkIndex: number) =>
                    <div key={`secretaryEvent-card-${secretaryEventIndex}-file-${linkIndex}`} className="app-secretary-events-card-line app-secretary-events-margin-botttom-10">
                      <a href={link.url} target="blank">{link.label}</a>
                    </div>
                  )}
                  { secretaryEvent.messages.map((message: string, messageIndex: number) =>
                    <div key={`secretaryEvent-card-${secretaryEventIndex}-message-${messageIndex}`} className="app-secretary-events-margin-botttom-10">{message}</div>
                  )}
                </Grid>
              </Grid>
          </Grid>
      )}
      </Grid>
      <div>&nbsp;</div>
    </div>
  )
}
