import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRecord } from "../../models/UserRecord";
import { UserRecordDog } from "../../models/UserRecordDog";
// import type { RootState } from '../store';

const initialState: UserRecord = {
  _id: "",
  createdDate: "",
  updatedDate: "",
  events: [] as string[],
  isEventAdmin: false,
  ownerAddress: {
    first: "",
    last: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
  },
  dogs: [] as UserRecordDog[],
};

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state._id = action.payload;
    },
    setCreatedDate: (state, action: PayloadAction<string>) => {
      state.createdDate = action.payload;
    },
    setUpdatedDate: (state, action: PayloadAction<string>) => {
      state.updatedDate = action.payload;
    },
    setIsEventAdmin: (state, action: PayloadAction<boolean>) => {
      state.isEventAdmin = action.payload;
    },
    setEvents: (state, action: PayloadAction<string[]>) => {
      state.events = action.payload;
    },
    setOwnerAddress(state, action: PayloadAction<any>) {
      if (action.payload.first !== undefined) {
        state.ownerAddress.first = action.payload.first;
      }
      if (action.payload.last !== undefined) {
        state.ownerAddress.last = action.payload.last;
      }
      if (action.payload.street !== undefined) {
        state.ownerAddress.street = action.payload.street;
      }
      if (action.payload.city !== undefined) {
        state.ownerAddress.city = action.payload.city;
      }
      if (action.payload.state !== undefined) {
        state.ownerAddress.state = action.payload.state;
      }
      if (action.payload.zip !== undefined) {
        state.ownerAddress.zip = action.payload.zip;
      }
      if (action.payload.phone !== undefined) {
        state.ownerAddress.phone = action.payload.phone;
      }
      if (action.payload.email !== undefined) {
        state.ownerAddress.email = action.payload.email;
      }
    },
    setDogs: (state, action: PayloadAction<UserRecordDog[]>) => {
      state.dogs = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<any>) => {
      if (action.payload._id !== undefined) {
        state._id = action.payload._id;
      }
      if (action.payload.createdDate !== undefined) {
        state.createdDate = action.payload.createdDate;
      }
      if (action.payload.updatedDate !== undefined) {
        state.updatedDate = action.payload.updatedDate;
      }
      if (action.payload.events !== undefined) {
        state.events = action.payload.events;
      }
      if (action.payload.isEventAdmin !== undefined) {
        state.isEventAdmin = action.payload.isEventAdmin;
      }
      if (action.payload.ownerAddress !== undefined) {
        state.ownerAddress = action.payload.ownerAddress;
      }
      if (action.payload.dogs !== undefined) {
        state.dogs = action.payload.dogs;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setId,
  setCreatedDate,
  setUpdatedDate,
  setEvents,
  setIsEventAdmin,
  setOwnerAddress,
  setUserProfile,
  setDogs,
} = userProfileSlice.actions;

// export const selectRegistrantName = (state: RootState) => state.registrant.name
// export const selectRegistrantEmail = (state: RootState) => state.registrant.email
// export const selectRegistrantAdminPin = (state: RootState) => state.registrant.adminPin

export default userProfileSlice.reducer;
