import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import _ from 'lodash';
import './entryFormPayment.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { setPayPalOrderHistory, setStepIndex } from '../../../redux/reducers/entryFormsSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { EntryFormRecordData } from '../../../models/EntryFormRecordData';
import { EntryFormEventInfo } from '../../../models/EntryFormEventInfo';
import axios from 'axios';
import EntryFormPrint from '../../atoms/entryFormPrint/entryFormPrint';

export default function EntryFormPayment() {

  const [ isLoading, setIsLoading ] = useState(true);
  const [ msg, setMsg ] = useState('');
  const [ clientId, setClientId ] = useState('');
  const [ totalFees, setTotalFees ] = useState(0);
  const [ eventId ] = useState(window.location.pathname === '/' ? '/no-event' : window.location.pathname)

  const { sumEntryFee, totalServiceFee, entries, payPalOrderHistory } = useAppSelector((state: any) => state.entryForms);
  const { serviceFee } = useAppSelector((state: any) => state.eventConfig);
  const { email } = useAppSelector((state: any) => state.registrant);

  const dispatch = useAppDispatch();

  const initialOptions = {
    clientId,
    currency: "USD",
    intent: "capture",
    "disable-funding": "paylater",
    "integration-date": "2022-04-13"
    // "data-client-token": "abc123xyz==",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const getClientIdRsp = await axios.get(`/api/payment/${email}`, { params: { eventId: eventId.replace('/', '') }});
        setClientId(_.get(getClientIdRsp, 'data.payPalClientId', ''));
        setIsLoading(false);
        setTotalFees(sumEntryFee + totalServiceFee);
      } catch(err: any) {
        console.log('get payments err=' + JSON.stringify(err, null, 2));
      }
    }
    if (!clientId) {
      fetchData();
    }
  });
  
  const getClasses = (eventEntries: EntryFormEventInfo[]) => {
    const retValue: string[] = [];
    eventEntries.forEach((eventEntry: EntryFormEventInfo) => {
      eventEntry.classes.forEach((eventEntryClass: any) => {
        if (eventEntryClass.isEntered) {
          retValue.push(`${eventEntry.eventId} : ${eventEntryClass.name} - Jump Ht: ${eventEntryClass.jumpHt}`);
        }
      });
    });
    return retValue;
  }

  return (
    <>
    { isLoading ?
      <h1>Loading</h1>
      :
      <>
        <Grid container className='noprint'>
          <Grid key={'entry-form-payment-header'} item md={12}>
            <h2>Please submit payment for the below entries.</h2>
          </Grid>
          { entries.map((entry: EntryFormRecordData, entryIndex: number) => (
            <Grid container direction="column" justifyContent="flex-start" p={2} key={'entry-form-payment-entry-' + entryIndex}>
              <Grid item className="entry-form-payment-dog-header">Call Name: { entry.callName }</Grid>
              <Grid item> Classes Entered:</Grid>
                { getClasses(entry.eventEntries).map((classEntered: string, classIndex: number) => (
                  <Grid key={'entry-form-payment-class-entered-' + classIndex}>{ classEntered }</Grid>
                ))}
              <Grid item className="entry-form-payment-bold">Entry Fee for this dog: ${entry.entryFee.toFixed(2)}</Grid>
            </Grid>
          ))}
          <Grid item md={12} id="entry-form-payment-total-entry-fees" className="entry-form-payment-dog-header">TOTAL ENTRY FEES (for all dogs): ${sumEntryFee.toFixed(2)}</Grid>
          { serviceFee ? 
              <Grid item md={12} id="entry-form-payment-total-service-fees" className="entry-form-payment-dog-header">TOTAL SERVICE FEES (for all dogs): ${totalServiceFee.toFixed(2)}</Grid>
            : <></>
          }
          <Grid item md={12}  id="entry-form-payment-total-fees" className="entry-form-payment-dog-header">TOTAL FEES (for all dogs): ${totalFees.toFixed(2)}</Grid>
        </Grid>
        <Grid container justifyContent="center" className='noprint'>
          <Grid item md={4} p={2}>
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons 
                createOrder={(data: any, actions: any) => {
                  return actions.order
                    .create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: 'USD',
                            value: `${totalFees}`
                          },
                        },
                      ],
                    })
                    .then((orderId: any) => {
                      return orderId;
                    });
                }}
                onApprove={(data: any, actions: any) => {
                  if (!actions || !actions.order) {
                    return Promise.resolve();
                  }
                  return actions.order.capture().then((details: any) => {
                    const updatedPayPalOrderHistory = _.cloneDeep(payPalOrderHistory);
                    updatedPayPalOrderHistory.push(details);
                    return axios.patch(`/api/payment/${email}`, details).then(() => {
                      dispatch(setPayPalOrderHistory(updatedPayPalOrderHistory));
                      setMsg(`Payment of $${_.get(details, 'purchase_units[0].amount.value')} received from ${_.get(details, 'payer.name.given_name')} ${_.get(details, 'payer.name.surname')}`)
                      dispatch(setStepIndex(3));
                      return axios.post(`/api/entryForm/${email}`, entries, { params: { eventId: eventId.replace('/', '') }});
                    });
                  });
                }}
                onCancel={(data: any) => {
                  const updatedPayPalOrderHistory = _.cloneDeep(payPalOrderHistory);
                  updatedPayPalOrderHistory.push(data);
                  setMsg(`Payment was canceled. Please try again, or enter via US Postal service`);
                  dispatch(setPayPalOrderHistory(updatedPayPalOrderHistory));
                  return axios.patch(`/api/payment/${email}`, data);
                }}
                onError={(err: any) => {
                  const updatedPayPalOrderHistory = _.cloneDeep(payPalOrderHistory);
                  updatedPayPalOrderHistory.push(err);
                  setMsg(`Payment encountered an error. Please try again, or enter via US Postal service`);
                  dispatch(setPayPalOrderHistory(updatedPayPalOrderHistory));
                  return axios.patch(`/api/payment/${email}`, err);
                }}
              />
            </PayPalScriptProvider>
          </Grid>
          <Grid item md={10} p={2}>
            { msg !== '' ?
              <div>
                <span className='entry-form-payment-clear-msg'>{msg}</span>
                <Button color="primary" style={{ textTransform: 'none'}} variant="outlined" onClick={(e: any) => setMsg('')}>Clear</Button>
              </div>
              : null
            }
          </Grid>
        </Grid>
        <EntryFormPrint />
      </>
    }
    </>
  )
}
