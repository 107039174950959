import React, { useState } from 'react';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { Delete as DeleteIcon, ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setFiles } from '../../../redux/reducers/eventConfigSlice';
import axios from 'axios';
import _ from 'lodash';
import './configViewFiles.css';
import { FileConfig } from '../../../models/FileConfig';

interface IConfigViewFiles {
  onChange: () => void
}
export default function ConfigViewFiles(props: IConfigViewFiles) {

  const { email, jwtToken } = useAppSelector((state: any) => state.registrant);
  const { _id, files } = useAppSelector((state: any) => state.eventConfig);

  const dispatch = useAppDispatch();

  const [ isUploading, setIsUploading ] = useState(false);
  
  const handleChangeFileField = (e: any, fileIndex: number, fieldName: string) => {
    const updatedFiles: FileConfig[] = _.cloneDeep(files);
    if (fieldName === 'filename') {
      updatedFiles[fileIndex].filename = e.target.value.split('\\')[2];
    } 
    if (fieldName === 'label') {
      updatedFiles[fileIndex].label = e.target.value;
    } 
    dispatch(setFiles(updatedFiles));
  }

  const isFileReadyToBeUploaded = (fileIndex: number) => {
    const currentFile = { ...files[fileIndex] };
    return currentFile.label !== '' && _.get(currentFile, 'filename', '') !== '' && !_.get(currentFile, 'isUploaded', false);
  }

  const handleClickAddFile = (e: any) => {
    const updatedFiles: FileConfig[] = _.cloneDeep(files);
    const newFile = {
      filename: '',
      label: '',
      url: '',
      isUploaded: false,
      startAvailableDate: new Date().toISOString(),
      endAvailableDate: new Date().toISOString()
    }
    updatedFiles.push(newFile);
    dispatch(setFiles(updatedFiles));
    props.onChange();
  }

  const handleClickDeleteFile = (fileIndex: number) => {
    const updatedFiles: FileConfig[] = _.cloneDeep(files);
    updatedFiles.splice(fileIndex, 1);
    dispatch(setFiles(updatedFiles));
    props.onChange();
  }

  const handleClickUploadFile = (e: any, fileIndex: number) => {
    const fileId = document.getElementById("file-" + fileIndex + "-id");
    setIsUploading(true);

    const fileReader = new FileReader();
    const filename = files[fileIndex].filename;

    /* istanbul ignore next */
    fileReader.onload = (e) => {
      const fileBinaryString = _.get(e, 'target.result', null);
      if (fileBinaryString !== null) {
        const formData = new FormData();
        formData.append('upload', _.get(fileId, 'files[0]', ''));
        return axios.post(`/api/protected/file/${filename}`, formData, { 
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${jwtToken}`
          },
          params: { 
            email, 
            fileLabel: files[fileIndex].label,
            fileStartAvailableDate: files[fileIndex].startAvailableDate,
            fileEndAvailableDate: files[fileIndex].endAvailableDate,
            eventId: _id
          }
        })
        .then((rsp: any) => {
          console.log(`DEBUG: file ${filename} uploaded to COS. fileIndex=${fileIndex}`);
          const updatedFiles: FileConfig[] = _.cloneDeep(files);
          updatedFiles[fileIndex].isUploaded = true;
          dispatch(setFiles(updatedFiles));
          setIsUploading(false);
        })
        .catch((err: any) => {
          console.log(`DEBUG: file ${filename} upload to COS FAILED with err=` + JSON.stringify(err, null, 2));
          setIsUploading(false);
        });
      }
    }
    fileReader.readAsBinaryString(_.get(fileId, 'files[0]', _.get(e, 'target.files[0]', { ok: false })));
    return fileReader;
  }

  const handleChangeDateField = (newValue: any, index: number, fieldName: string)  => {
    try {
      const newDateISO = new Date(newValue).toISOString();
      const updatedFiles = _.cloneDeep(files);
      if (fieldName === 'startAvailableDate') {
        updatedFiles[index].startAvailableDate = newDateISO;
      }
      if (fieldName === 'endAvailableDate') {
        updatedFiles[index].endAvailableDate = newDateISO;
      }
      dispatch(setFiles(updatedFiles));
      props.onChange();
    }
    catch {}
  }

  const handleClickMoveFile = (index: number, direction: string) => {
    const updatedFiles = _.cloneDeep(files);
    const file = updatedFiles[index];
    if (direction === 'up') {
      updatedFiles.splice(index, 1);
      updatedFiles.splice(index - 1, 0, file);
    }
    if (direction === 'down') {
      updatedFiles.splice(index, 1);
      updatedFiles.splice(index + 1, 0, file);
    }
    dispatch(setFiles(updatedFiles));
    props.onChange();
  }

  return (
    <>
      <Grid key={'event-file-header'} item md={12}>
        <h2>File downloads</h2>
      </Grid>
      { files.map((file: any, fileIndex: number) => (
        <Grid key={'event-file-' + fileIndex} container>
          <Grid  item md={12} p={2}>
            <div className="config-view-file">
              <TextField 
                id={'file-' + fileIndex + '-label'}
                variant="outlined"
                size="small"
                className="config-view-file-field-label"
                value={files[fileIndex]['label']}
                onChange={(e: any) => handleChangeFileField(e, fileIndex, 'label')}
                label='label' />
              { files[fileIndex].isUploaded ? 
                <span className="config-view-file-input">{ files[fileIndex].filename }</span>
                :
                <input
                  id={'file-' + fileIndex + '-id' }
                  type="file"
                  className="config-view-file-input"
                  onChange={(e) => handleChangeFileField(e, fileIndex, 'filename')}
                  />
              }
              <Button 
                id={'file-' + fileIndex + '-upload-button'}
                key={fileIndex}
                color="primary" 
                aria-label="upload file"
                component="span"
                style={{textTransform: 'none'}}
                disabled={ isUploading || !isFileReadyToBeUploaded(fileIndex) }
                onClick={(e: any) => handleClickUploadFile(e, fileIndex)}>
                { files[fileIndex].isUploaded ? 'Uploaded' : 'Upload' }
              </Button>
              <Tooltip title="Move up">
                <IconButton 
                  id={'file-' + fileIndex + '-moveup-button'}
                  key={fileIndex}
                  color="primary" 
                  aria-label="move up"
                  component="span"
                  disabled={ fileIndex === 0 }
                  onClick={() => handleClickMoveFile(fileIndex, 'up')}>
                  <ArrowUpwardIcon style={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Move down">
                <IconButton 
                  id={'file-' + fileIndex + '-movedown-button'}
                  key={fileIndex}
                  color="primary" 
                  aria-label="move down"
                  component="span"
                  disabled={ fileIndex === (files.length - 1) }
                  onClick={() => handleClickMoveFile(fileIndex, 'down')}>
                  <ArrowDownwardIcon style={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete file">
                <IconButton 
                  id={'file-' + fileIndex + '-delete-button'}
                  key={fileIndex}
                  color="primary" 
                  aria-label="delete file"
                  component="span"
                  onClick={() => handleClickDeleteFile(fileIndex)}>
                  <DeleteIcon style={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Grid item md={12} p={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Start Available"
                  value={files[fileIndex].startAvailableDate}
                  onChange={(newValue) => handleChangeDateField(newValue, fileIndex, 'startAvailableDate')}
                  renderInput={(params) => <TextField { ...params } id="config-view-files-start-available-date" sx={{ m: 1 }} />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="End Available"
                  value={files[fileIndex].endAvailableDate}
                  onChange={(newValue: any) => handleChangeDateField(newValue, fileIndex, 'endAvailableDate')}
                  renderInput={(params) => <TextField { ...params } id="config-view-files-end-available-date" sx={{ m: 1 }} />}
                />
              </LocalizationProvider>
          </Grid>
        </Grid>
        ))}
      
      <Grid key={'event-file-add'} item md={12}>
        <div className="config-view-file-add">
          <Button 
            id="configView-add-file"
            color="primary" 
            variant="contained"
            className="config-view-button"
            style={{textTransform: 'none'}}
            onClick={(e: any) => handleClickAddFile(e)}>
            Add Download
          </Button>
        </div>
      </Grid>
    </>
  )
}
