import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfigRecord } from "../../models/ConfigRecord";
import { DateConfig } from "../../models/DateConfig";
import { EntryEventConfig } from "../../models/EntryEventConfig";
import { FileConfig } from "../../models/FileConfig";
import { ReservationInfo } from "../../models/ReservationInfo";
import { SessionConfig } from "../../models/SessionConfig";
import { SecretaryEventConfig } from "../../models/SecretaryEventConfig";
import { SecretaryResultConfig } from "../../models/SecretaryResultConfig";

const initialState: ConfigRecord = {
  _id: "",
  title: "",
  createdBy: "",
  contactName: "",
  contactEmail: "",
  contactPronoun: "",
  notices: [] as string[],
  editors: [] as string[],
  endAvailableDate: "",
  sessions: [] as SessionConfig[],
  reservations: [] as ReservationInfo[],
  files: [] as FileConfig[],
  dates: [] as DateConfig[],
  entryEvents: [] as EntryEventConfig[],
  secretaryEvents: [] as SecretaryEventConfig[],
  secretaryResults: [] as SecretaryResultConfig[],
  active: false,
  template: "",
  anonymousOnly: false,
  maxStudents: 0,
  signupGranularity: "slot",
  startAvailableDate: "",
  payPalClientId: "",
  updatedDate: "",
  url: "",
  deleteEventConfirmDialog: false,
  activeSessionIndex: -1,
  serviceFee: 0,
  serviceFeeBasis: "owner",
};

export const eventConfigSlice = createSlice({
  name: "eventConfig",
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state._id = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setCreatedBy: (state, action: PayloadAction<string>) => {
      state.createdBy = action.payload;
    },
    setSessions: (state, action: PayloadAction<SessionConfig[]>) => {
      state.sessions = action.payload;
    },
    setFiles: (state, action: PayloadAction<FileConfig[]>) => {
      state.files = action.payload;
    },
    setDates: (state, action: PayloadAction<DateConfig[]>) => {
      state.dates = action.payload;
    },
    setEntryEvents: (state, action: PayloadAction<EntryEventConfig[]>) => {
      state.entryEvents = action.payload;
    },
    setSecretaryEvents: (state, action: PayloadAction<SecretaryEventConfig[]>) => {
      state.secretaryEvents = action.payload;
    },
    setSecretaryResults: (state, action: PayloadAction<SecretaryResultConfig[]>) => {
      state.secretaryResults = action.payload;
    },
    setActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
    setEditors: (state, action: PayloadAction<string[]>) => {
      state.editors = action.payload;
    },
    setTemplate: (state, action: PayloadAction<string>) => {
      state.template = action.payload;
    },
    setAnonymousOnly: (state, action: PayloadAction<boolean>) => {
      state.anonymousOnly = action.payload;
    },
    setMaxStudents: (state, action: PayloadAction<number>) => {
      state.maxStudents = action.payload;
    },
    setSignupGranularity: (state, action: PayloadAction<string>) => {
      state.signupGranularity = action.payload;
    },
    setPayPalClientId: (state, action: PayloadAction<string>) => {
      state.payPalClientId = action.payload;
    },
    setUpdatedDate: (state, action: PayloadAction<string>) => {
      state.updatedDate = action.payload;
    },
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setDeleteEventConfirmDialog: (state, action: PayloadAction<boolean>) => {
      state.deleteEventConfirmDialog = action.payload;
    },
    setActiveSessionIndex: (state, action: PayloadAction<number>) => {
      state.activeSessionIndex = action.payload;
    },
    setContactName: (state, action: PayloadAction<string>) => {
      state.contactName = action.payload;
    },
    setContactEmail: (state, action: PayloadAction<string>) => {
      state.contactEmail = action.payload;
    },
    setContactPronoun: (state, action: PayloadAction<string>) => {
      state.contactPronoun = action.payload;
    },
    setNotices: (state, action: PayloadAction<string[]>) => {
      state.notices = action.payload;
    },
    setStartAvailableDate: (state, action: PayloadAction<string>) => {
      state.startAvailableDate = action.payload;
    },
    setEndAvailableDate: (state, action: PayloadAction<string>) => {
      state.endAvailableDate = action.payload;
    },
    setServiceFee: (state, action: PayloadAction<string>) => {
      state.serviceFee = parseFloat(action.payload);
    },
    setServiceFeeBasis: (state, action: PayloadAction<string>) => {
      state.serviceFeeBasis = action.payload;
    },
    setEventConfig: (state, action: PayloadAction<any>) => {
      if (action.payload._id !== undefined) {
        state._id = action.payload._id;
      }
      if (action.payload.title !== undefined) {
        state.title = action.payload.title;
      }
      if (action.payload.createdBy !== undefined) {
        state.createdBy = action.payload.createdBy;
      }
      if (action.payload.editors !== undefined) {
        state.editors = action.payload.editors;
      }
      if (action.payload.sessions !== undefined) {
        state.sessions = action.payload.sessions;
      }
      if (action.payload.files !== undefined) {
        state.files = action.payload.files;
      }
      if (action.payload.dates !== undefined) {
        state.dates = action.payload.dates;
      }
      if (action.payload.entryEvents !== undefined) {
        state.entryEvents = action.payload.entryEvents;
      }
      if (action.payload.secretaryEvents !== undefined) {
        state.secretaryEvents = action.payload.secretaryEvents;
      }
      if (action.payload.secretaryResults !== undefined) {
        state.secretaryResults = action.payload.secretaryResults;
      }
      if (action.payload.active !== undefined) {
        state.active = action.payload.active;
      }
      if (action.payload.anonymousOnly !== undefined) {
        state.anonymousOnly = action.payload.anonymousOnly;
      }
      if (action.payload.template !== undefined) {
        state.template = action.payload.template;
      }
      if (action.payload.maxStudents !== undefined) {
        state.maxStudents = action.payload.maxStudents;
      }
      if (action.payload.signupGranularity !== undefined) {
        state.signupGranularity = action.payload.signupGranularity;
      }
      if (action.payload.payPalClientId !== undefined) {
        state.payPalClientId = action.payload.payPalClientId;
      }
      if (action.payload.updatedDate !== undefined) {
        state.updatedDate = action.payload.updatedDate;
      }
      if (action.payload.url !== undefined) {
        state.url = action.payload.url;
      }
      if (action.payload.deleteEventConfirmDialog !== undefined) {
        state.deleteEventConfirmDialog = action.payload.deleteEventConfirmDialog;
      }
      if (action.payload.activeSessionIndex !== undefined) {
        state.activeSessionIndex = action.payload.activeSessionIndex;
      }
      if (action.payload.contactName !== undefined) {
        state.contactName = action.payload.contactName;
      }
      if (action.payload.contactEmail !== undefined) {
        state.contactEmail = action.payload.contactEmail;
      }
      if (action.payload.contactPronoun !== undefined) {
        state.contactPronoun = action.payload.contactPronoun;
      }
      if (action.payload.notices !== undefined) {
        state.notices = action.payload.notices;
      }
      if (action.payload.startAvailableDate !== undefined) {
        state.startAvailableDate = action.payload.startAvailableDate;
      }
      if (action.payload.endAvailableDate !== undefined) {
        state.endAvailableDate = action.payload.endAvailableDate;
      }
      if (action.payload.secretaryEvents !== undefined) {
        state.secretaryEvents = action.payload.secretaryEvents;
      }
      if (action.payload.serviceFee !== undefined) {
        state.serviceFee = action.payload.serviceFee;
      }
      if (action.payload.serviceFeeBasis !== undefined) {
        state.serviceFeeBasis = action.payload.serviceFeeBasis;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setId,
  setTitle,
  setCreatedBy,
  setContactName,
  setContactEmail,
  setContactPronoun,
  setNotices,
  setEditors,
  setSessions,
  setFiles,
  setDates,
  setEntryEvents,
  setSecretaryEvents,
  setSecretaryResults,
  setActive,
  setTemplate,
  setAnonymousOnly,
  setMaxStudents,
  setSignupGranularity,
  setUpdatedDate,
  setUrl,
  setDeleteEventConfirmDialog,
  setActiveSessionIndex,
  setPayPalClientId,
  setEventConfig,
  setStartAvailableDate,
  setEndAvailableDate,
  setServiceFee,
  setServiceFeeBasis,
} = eventConfigSlice.actions;

export default eventConfigSlice.reducer;
