import React from 'react';
import { Button, Grid } from '@mui/material';
import './loginMessage.css';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setShowLoginMessage } from '../../../redux/reducers/registrantSlice';

export default function LoginMessage() {

  const { showLoginMessage } = useAppSelector((state: any) => state.registrant);
  const dispatch = useAppDispatch();

  return (
    <>
      { showLoginMessage ? 
        <div className="login-message-container noprint">
          <Grid container alignItems="center" alignContent="flex-end">
            <Grid item md={12} xs={12} className="login-message-grid-item"  p={2}>
              <div className="login-message">
                <div style={{ fontWeight: 'bold'}}>Do I need to log in?</div>
                <div>
                  Logging in is optional. You can start from a blank form each trial. 
                  Whether you are logged in or not, you have the same access to content.
                </div>
                <div>
                  If you choose to Login, you can manage your information (address, phone, etc) and 
                  information about your dog(s) by editting your profile. The data from your profile can be used when 
                  filling out an entry form, making it faster and more efficient (not having to retype the same information each trial). 
                </div>
              </div>
            </Grid>
            <Grid item md={12} xs={12} className="login-message-grid-item" p={1}>
              <Button id="login-message-dismiss" style={{ textTransform: 'none', color: 'white' }} variant="outlined"  onClick={(e: any) => dispatch(setShowLoginMessage(false))}>Dismiss</Button>
            </Grid>
          </Grid>
        </div>
        : null
      }
    </>
  )
}
