import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntryFormRecordData } from "../../models/EntryFormRecordData";
// import type { RootState } from '../store';

const initialState: any = {
  entries: [] as EntryFormRecordData[],
  edittingIndex: -1,
  stepIndex: 0,
  sumEntryFee: 0 as number,
  totalServiceFee: 0 as number,
  agreeToAgreementDate: "",
  payPalOrderHistory: [] as any[],
};

export const entryFormsSlice = createSlice({
  name: "entryForms",
  initialState,
  reducers: {
    setEntries: (state, action: PayloadAction<EntryFormRecordData[]>) => {
      state.entries = action.payload;
    },
    setEdittingIndex: (state, action: PayloadAction<number>) => {
      state.edittingIndex = action.payload;
    },
    setStepIndex: (state, action: PayloadAction<number>) => {
      state.stepIndex = action.payload;
    },
    setSumEntryFee: (state, action: PayloadAction<number>) => {
      state.sumEntryFee = action.payload;
    },
    setTotalServiceFee: (state, action: PayloadAction<number>) => {
      state.totalServiceFee = action.payload;
    },
    setPayPalOrderHistory: (state, action: PayloadAction<any[]>) => {
      state.payPalOrderHistory = action.payload;
    },
    setAgreeToAgreementDate: (state, action: PayloadAction<string>) => {
      state.agreeToAgreementDate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAgreeToAgreementDate,
  setEdittingIndex,
  setEntries,
  setPayPalOrderHistory,
  setStepIndex,
  setSumEntryFee,
  setTotalServiceFee,
} = entryFormsSlice.actions;

// export const selectRegistrantName = (state: RootState) => state.registrant.name
// export const selectRegistrantEmail = (state: RootState) => state.registrant.email
// export const selectRegistrantAdminPin = (state: RootState) => state.registrant.adminPin

export default entryFormsSlice.reducer;
