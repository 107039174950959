import React, { Component } from 'react';
import { Button, Grid } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import './downloadInfo.css';
import { FileConfig } from '../../../models/FileConfig';
import moment from 'moment-timezone';

interface IDownloadInfo {
  eventId: string,
  files: FileConfig[],
  dataFetchTimeISO: string
}

interface IState {
  isDownloading: boolean[];
  message: string[];
}

class DownloadInfo extends Component<IDownloadInfo, IState> {

  constructor(props: IDownloadInfo) {
    super(props);
    this.state = {
      isDownloading: new Array(this.props.files.length).fill(false),
      message: new Array(this.props.files.length).fill('')
    }
  }

  downloadFile(e: any, index: number) {
    e.preventDefault();
    const myState = this.state;
    myState.isDownloading[index] = true;
    myState.message[index] = '';
    this.setState(myState);
    const fileLabel = e.target.innerText.replace('Download ', '').replace(/\n/,'');
    return axios.get(`/api/file${this.props.eventId}`, { params: { fileLabel }})
    .then((res: any) => {
      if (_.get(res.data, 'error.message', '') !== '') {
        const myState = this.state;
        myState.isDownloading[index] = false;
        myState.message[index] = 'Error downloading file. Try again.';
        this.setState(myState);
        return;
      }
      const base64str = res.data.data;

      // decode base64 string, remove space for IE compatibility
      var binary = atob(base64str.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
      }

      var blob = new Blob( [view], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${fileLabel}.pdf`,
      );

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      const myState = this.state;
      myState.isDownloading[index] = false;
      this.setState(myState);
    });
  }

  isDownloadInProgress() {
    return _.findIndex(this.state.isDownloading, val => val) > -1 ? true : false;
  }

  isAvailableTime(index: number) {
    if (
      _.get(this.props.files[index], 'startAvailableDate', '') === '' &&
      _.get(this.props.files[index], 'endAvailableDate', '') === ''
    ) {
      return true;
    }
    if (
      _.get(this.props.files[index], 'startAvailableDate', '') !== '' &&
      _.get(this.props.files[index], 'endAvailableDate', '') !== '' &&
      this.props.dataFetchTimeISO >  this.props.files[index].startAvailableDate &&
      this.props.dataFetchTimeISO <  this.props.files[index].endAvailableDate
    ) {
      return true;
    }
    if (
      _.get(this.props.files[index], 'startAvailableDate', '') !== '' &&
      _.get(this.props.files[index], 'endAvailableDate', '') === '' &&
      this.props.dataFetchTimeISO >  this.props.files[index].startAvailableDate
    ) {
      return true;
    }
    if (
      _.get(this.props.files[index], 'startAvailableDate', '') === '' &&
      _.get(this.props.files[index], 'endAvailableDate', '') !== '' &&
      this.props.dataFetchTimeISO <  this.props.files[index].endAvailableDate
    ) {
      return true;
    }
    return false;
  }

  getAvailableMessage(index: number) {
    let msg = 'Download not available';
    if (
      _.get(this.props.files[index], 'startAvailableDate', '') !== '' &&
      this.props.dataFetchTimeISO < this.props.files[index].startAvailableDate
    ) {
      msg += ' until ' + moment(this.props.files[index].startAvailableDate).tz('America/New_York').format('MM/DD/YYYY hh:mm a') + ' ET';
    }
    if (
      _.get(this.props.files[index], 'endAvailableDate', '') !== '' &&
      this.props.dataFetchTimeISO > this.props.files[index].endAvailableDate
    ) {
      msg += ' after ' + moment(this.props.files[index].endAvailableDate).tz('America/New_York').format('MM/DD/YYYY hh:mm a') + ' ET';
    }
    return msg;
  }

  render() {
    return (
      <div className="noprint">
        <h2>Downloads</h2>
        <Grid container alignItems="center" alignContent="flex-end">
          { this.props.files.map((file: FileConfig, index: number) => (
            <React.Fragment key={file.label}>
              <Grid item md={12} xs={12} p={1}>
                <Button
                  id={ 'download-file-' + index }
                  color="primary"
                  variant={ !this.isAvailableTime(index) ? 'outlined' : 'contained' }
                  style={{textTransform: 'none' }} 
                  disabled={ !this.isAvailableTime(index) || this.isDownloadInProgress()}
                  onClick={(e: any) => this.downloadFile(e, index) }
                  >
                  { this.state.isDownloading[index] ? 'Downloading' : 'Download' } {file.label}
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                { !this.isAvailableTime(index) ? 
                  <span className="download-info-message">{ this.getAvailableMessage(index) }</span>
                  : null
                }
                { this.state.message[index] !== '' ? 
                  <span>{ this.state.message[index] }</span>
                  : null
                }
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </div>
    )
  }
}

export default DownloadInfo
