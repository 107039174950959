import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import _ from 'lodash';
import './entryFormDone.css';
import { useAppSelector } from '../../../redux/hooks';
import EntryFormPrint from '../../atoms/entryFormPrint/entryFormPrint';
import { UserRecordDog } from '../../../models/UserRecordDog';
import { EntryFormRecordData } from '../../../models/EntryFormRecordData';
import { UserRecord } from '../../../models/UserRecord';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default function EntryFormDone() {

  const { entries } = useAppSelector((state: any) => state.entryForms);
  const { email, isAuth, jwtToken } = useAppSelector((state: any) => state.registrant);
  const { dogs } = useAppSelector((state: any) => state.userProfile);
  const [ dogUpdates, setDogUpdates ] = useState<string[]>([]);
  const [ saveInProgress, setSaveInProgress ] = useState<boolean>(false);
  const [ saveMsg, setSaveMsg ] = useState<string>('');

  const getFriendlyName = (fieldName: string) => {
    if (fieldName === 'akcName') {
      return 'AKC Name';
    } else if (fieldName === 'akcRegistrationNum') {
      return 'AKC Reg #';
    } else if (fieldName === 'jrHandlerName') {
      return 'Jr Name';
    } else if (fieldName === 'jrHandlerNumber') {
      return 'Jr Number';
    } else if (fieldName === 'dateOfBirth') {
      return 'Date of Birth';
    } else if (fieldName === 'countryOfBirth') {
      return 'Country of Birth';
    }
    return fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
  }
  
  useEffect(() => {
    const updates: string[] = [];
    /* istanbul ignore next */
    if (entries && dogs) {
      entries.forEach((entriesDog: EntryFormRecordData, entriesDogIndex: number) => {
        const dogsIndex = _.findIndex(dogs, (val: UserRecordDog) => val.callName === entriesDog.callName);
        const fieldsUpdated: string[] = [];
        if (dogsIndex === -1) {
          fieldsUpdated.push('New Dog');
        } else {
          const fields = Object.keys(dogs[dogsIndex]);
          fields.forEach((field: string) => {
            if (entries[entriesDogIndex][`${field}`] !== dogs[dogsIndex][`${field}`]) {
              if (field !== 'uuid') {
                fieldsUpdated.push(getFriendlyName(field));
              }
            }
          });
        }
        updates.push(fieldsUpdated.length === 0 ? 'No changes' : fieldsUpdated.toString());
      });
    }
    setDogUpdates(updates);
  },[dogs, entries]);


const handleClickUpdateEntry = async (e: any, callName: string) => {
  e.preventDefault();

  try {
    setSaveInProgress(true);
    const userRsp = await axios.get(`/api/protected/user/${email}`, {
      headers: { 
        Authorization: `Bearer ${jwtToken}`,
      }
    });
    if (userRsp.data && !userRsp.data.error) {
      const userRecord: UserRecord = userRsp.data;
      const userRecordDogIndex = _.findIndex(userRecord.dogs, (val: UserRecordDog) => val.callName === callName);
      const entriesIndex = _.findIndex(entries, (val: EntryFormRecordData) => val.callName === callName);
      const entry: EntryFormRecordData = entries[entriesIndex];
  
      const updatedDog: UserRecordDog = {
        uuid: uuidv4(),
        breed: entry.breed,
        variety: entry.variety,
        sex: entry.sex,
        jrHandlerName: entry.jrHandlerName,
        jrHandlerNumber: entry.jrHandlerNumber,
        callName: entry.callName,
        akcName: entry.akcName,
        akcRegistrationNum: entry.akcRegistrationNum,
        dateOfBirth: entry.dateOfBirth,
        countryOfBirth: entry.countryOfBirth,
        breeder: entry.breeder,
        sire: entry.sire,
        dam: entry.dam,
        owners: entry.owners,
        handler: entry.handler
      }
      if (userRecordDogIndex === -1) {
        userRecord.dogs.push(updatedDog);
      } else {
        updatedDog.uuid = entry.uuid;
        userRecord.dogs[userRecordDogIndex] = updatedDog;
      }

      const dataRsp: any = await axios.post(`/api/protected/user/${userRecord._id}`, userRecord, { headers: { Authorization: `Bearer ${jwtToken}` }, params: { email }});
      if (dataRsp.data.ok) {
        const updatedDogUpdates = _.cloneDeep(dogUpdates);
        updatedDogUpdates[entriesIndex] = 'No changes';
        setDogUpdates(updatedDogUpdates);
        setSaveMsg(`Profile updated successfully for ${callName}`);
      } else {
        setSaveMsg(`Profile update failed for ${callName}`);
      }
    } else {
      setSaveMsg(`Profile update failed for ${callName}`);
    }
    setSaveInProgress(false);
  } catch(err: any) {
    setSaveMsg(`Profile update failed for ${callName}`);
    setSaveInProgress(false);
  }
 }

 const getUpdateMsg = (dogIndex: number) => {
  if (['New Dog', 'No changes'].includes(dogUpdates[dogIndex])) {
    return dogUpdates[dogIndex];
  }
  return `Fields to be updated: ${dogUpdates[dogIndex]}`;
 }

  return (
    <Grid container>
      <Grid item md={12} p={2} justifyContent="flex-start" className='noprint'>
        <Grid item md={12} p={2} className="entry-form-done-msg">
          Congratulations! You successfully submitted {entries.length} entry { entries.length > 1 ? 'forms' : 'form' } to the trial secretary. 
          A copy of the entry { entries.length > 1 ? 'forms' : 'form' } submitted was also emailed to {_.get(entries, '[0].email')}.
        </Grid>
        <Grid item md={12}>
          This confirms your submission, and does not necessarily mean the secretary has accepted
          your entry into the trial.
        </Grid>
        <Grid item p={2} md={12}className="entry-form-done-sec-contact">The secretary will be in touch soon to confirm the acceptance of the entry(s).</Grid>
        <Grid item p={2} md={12}className="entry-form-done-sec-contact">Thank you for entering!</Grid>
        { isAuth && entries ?
          <>
            { entries?.map((dog: UserRecordDog, dogIndex: number) =>
              <Grid container key={`user-record-dog-${dogIndex}`} spacing={2} justifyContent="center" alignItems="center">
                <Grid item md={1} p={2}>
                  <div className="entry-form-done-call-name">{ dog.callName }:</div>
                </Grid>
                <Grid item md={2} p={2}>
                  <Button 
                    id={`entry-form-done-existing-dog-${dogIndex}`}
                    color="primary" 
                    variant="contained"
                    className="entry-form-done-button"
                    style={{textTransform: 'none'}}
                    disabled={ dogUpdates[dogIndex] === 'No changes' || saveInProgress }
                    onClick={(e: any) => handleClickUpdateEntry(e, dog.callName)}>
                    Update profile for { dog.callName }
                  </Button>
                </Grid>
                <Grid item md={3} p={2}>
                  <div  id={`entry-form-done-existing-dog-${dogIndex}-fields-to-update`}>
                    { getUpdateMsg(dogIndex) }
                  </div>
                </Grid>
              </Grid>
            )}
            { saveMsg !== '' ?
              <Grid item md={12} p={2}>
                <div id="entry-form-done-save-msg">{ saveMsg }</div>
              </Grid>
              : null
            }
          </>
          : null
        }

      </Grid>
      <EntryFormPrint />
    </Grid>
  )
}
