import React from 'react';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setSessions } from '../../../redux/reducers/eventConfigSlice';
import './configViewSessions.css';
import { SessionConfig } from '../../../models/SessionConfig';
import _ from 'lodash';

interface IConfigViewSessions {
  onChange: () => void
}

export default function ConfigViewSessions(props: IConfigViewSessions) {

  const { sessions } = useAppSelector((state: any) => state.eventConfig);

  const dispatch = useAppDispatch();

  const getSessionFields = () => {
    return ['name', 'numberSlotsOffered', 'pricePerRun', 'runs', 'sortOrder', 'sortField' ];
  }

  const handleChangeSessionField = (e: any, sessionIndex: number, fieldName: string) => {
    const updatedSessions: any[] = _.cloneDeep(sessions);
    const arrayFields = ['sortOrder', 'runs'];
    const integerFields = ['pricePerRun', 'numberSlotsOffered'];
    if (arrayFields.includes(fieldName)) {
      updatedSessions[sessionIndex][fieldName] = e.target.value.split(',');
    } else if (integerFields.includes(fieldName) && e.target.value.match(/^\d*$/) && e.target.value !== '') {
      updatedSessions[sessionIndex][fieldName] = parseInt(e.target.value);
    } else {
      updatedSessions[sessionIndex][fieldName] = e.target.value;
    }    
    dispatch(setSessions(updatedSessions));
    props.onChange();
  }

  const handleClickAddSession = () => {
    const updatedSessions: SessionConfig[] = _.cloneDeep(sessions);
    const newSession: SessionConfig = {
      numberSlotsOffered: 0,
      pricePerRun: 0,
      name: '',
      sortOrder: [],
      sortField: 'jumpHt',
      runs: [],
      reservations: []
    }
    const numExistingSessions = sessions.length;
    if (numExistingSessions > 0) {
      const mostRecentSession = sessions[numExistingSessions - 1];
      newSession.numberSlotsOffered = mostRecentSession.numberSlotsOffered;
      newSession.pricePerRun = mostRecentSession.pricePerRun;
      newSession.sortOrder = mostRecentSession.sortOrder;
      newSession.runs = mostRecentSession.runs;
    }
    updatedSessions.push(newSession);
    dispatch(setSessions(updatedSessions));
    props.onChange();
  }

  const handleClickDeleteSession = (sessionIndex: number) => {
    const updatedSessions: SessionConfig[] = _.cloneDeep(sessions);
    updatedSessions.splice(sessionIndex, 1);
    dispatch(setSessions(updatedSessions));
    props.onChange();
  }

  const getErrorMessage = (sessionIndex: number, fieldName: string)  => {
    const fieldValue = sessions[sessionIndex][fieldName];

    if (fieldValue === '') {
      return 'Required';
    }
    
    if (fieldName.includes('pricePerRun') || fieldName.includes('numberSlotsOffered')) {
      if (fieldValue !== '' && typeof fieldValue === 'string' && !fieldValue.match(/^\d*$/)) {
        return 'Must be a number';
      }
    }

    if (fieldName.includes('runs') || fieldName.includes('sortOrder')) {
      const runs = sessions[sessionIndex].runs;
      const sortOrder = sessions[sessionIndex].sortOrder;
      if (runs.length !== sortOrder.length) {
        return 'Runs and SortOrder need to be same number of values (comma separated)';
      }
    }

    if (fieldName.includes('sortOrder')) {
      const validValues = [ 'asc', 'desc', 'manual'];
      for (let i =0; i < fieldValue.length; i++) {
        if (!validValues.includes(fieldValue[i])) {
          return `Valid values are ${validValues.toString()}. No spaces before nor after comma separator.`;
        }
      };
    }

    if (fieldName.includes('sortField')) {
      const validValues = [ 'name', 'jumpHt'];
      if (!validValues.includes(fieldValue)) {
        return `Must be one of ${validValues.toString()}`;
      }
    }
  }

  return (
    <>
      <Grid key={'event-session-header'} item md={12}>
        <h2>Sessions</h2>
      </Grid>
      {sessions.map((session: any, sessionIndex: number) => (
        <React.Fragment key={'event-session-header-' + sessionIndex + '-div'}>
          <Grid key={'event-session-header-' + sessionIndex} item md={12}>
            <div>
              <span className="config-view-session-header">Session {sessionIndex + 1}</span>
              <Tooltip title="Delete session">
                <IconButton
                  id={'current-event-session-' + sessionIndex + '-delete'}
                  key={sessionIndex}
                  color="primary"
                  aria-label="delete session"
                  component="span"
                  onClick={() => handleClickDeleteSession(sessionIndex)}>
                  <DeleteIcon style={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          {getSessionFields().map((sessionFieldName: string, sessionFieldIndex: number) => (
            <Grid key={'event-session-' + sessionIndex + '-field-' + sessionFieldIndex} item md={12}>
              <div>
                <TextField
                  id={'current-event-session-' + sessionIndex + '-' + sessionFieldName}
                  variant="outlined"
                  size="small"
                  className={'config-view-session-field-' + sessionFieldName}
                  value={sessions[sessionIndex][sessionFieldName]}
                  onChange={(e) => handleChangeSessionField(e, sessionIndex, sessionFieldName)}
                  label={sessionFieldName} />
                <span className="config-view-session-field-error">{getErrorMessage(sessionIndex, sessionFieldName)}</span>
              </div>
            </Grid>
          ))}
        </React.Fragment>
      ))}
      <Grid key={'event-session-add-buttons'} item md={12}>
        <div className="config-view-button-wrapper">
          <Button
            id="configView-add-session"
            color="primary"
            variant="contained"
            className="config-view-button"
            style={{ textTransform: 'none' }}
            onClick={() => handleClickAddSession()}>
            Add Session
          </Button>
        </div>
      </Grid>
    </>
  )
}
