import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import _ from 'lodash';
import './helpDialog.css';

interface IHelpDialog {
  subject: string
}

interface IState {
  open: boolean,
  text: string
}

class HelpDialog extends Component<IHelpDialog, IState> {

  constructor(props: IHelpDialog) {
    super(props);
    this.state = {
      open: false,
      text: ''
    }
  }

  componentDidMount() {
    const subject = this.props.subject;

    const subjectMap:any[] = [
      {
        key: '_id',
        value: 'The value cannot contain spaces nor symbols. Valid characters are A-Z, a-z, 0-9, -, and _'
      },
      {
        key: 'title',
        value: 'A brief description of the event, e.g. Durham Kennel Club Agility Show-N-Go April 3, 2021.'
      },
      {
        key: 'template',
        value: `Each template type has different configuration, user experience, and admin experience. ` +
                `Ad-Hoc is for an unstructures class. ` +
                `Class participants will go to the web page, and click on 'Cound me in'. Then they can see others who accepted. ` +
                `If they later change their mind, they can go to the sameweb page and click on 'Opps, I can't come'. ` +
                `You will configure potentially 10-50 participants that you will want to email and you'll configure dates as you decide on them. ` +
                `You can also add course map(s) to the page so participants can easily access them. ` +
                `Class is similar to Ad-Hoc, but for a structured. ` +
                `Class participants will go to the web page, and click on 'I can't make it this week'. They can see students in the class and their status. ` +
                `If they later change their mind, they can go to the same web page and click on 'Opps, I can come'. ` +
                `You will configure potentially 1-12 students that you will want to email and you'll configure dates. ` +
                `You can also add course map(s) to the page so participants can easily access them. ` +
                `Show-N-Go is different, there's no pre-established list. ` + 
                `Anyone with the URL can go and sigh up. They can never see other participants, only their info. They can entry multiple dogs, and jump heights. ` +
                `They can later cancel their entry. ` +
                `Auction is very different, there's no pre-established list. ` + 
                `Anyone with the URL can go and see their latest bid, and submit a new bid. ` +
                `They can never see other participant names, just the bid that's highest.`
      },
      {
        key: 'signupGranularity',
        value: `This could be generally applicable, but is most useful for Show-N-Gos.` +
                `'slot' means that the user experience is that they are signing up for a specific slot, and would expect to run in the order in which they signed up. ` +
                `'session' means that the user is just signing up for a session, and the organizer will decide on the running order, e.g. by jump height.`
      },
      {
        key: 'anonymousOnly',
        value: `This could be generally applicable, but is most useful for Files.` +
                `'checked' means that the user will NOT be prompted for their name and email. For Files, when you don't want to track precisely who is ` +
                `visiting/downloading the file(s), this makes for a cleaner and less confusing interface.`
      },
      {
        key: 'payPalClientId',
        value: `Payments, for example entry fees, will be deposited directly into your PayPal account.` +
                `In order to do so, you need: ` +
                ` 1) login to your Club's PayPal business account, ` + 
                ` 2) go to https://developer.paypal.com/developer/applications , ` +
                ` 3) Under My apps & credentials select 'Live', ` +
                ` 4) select 'Create App', ` +
                ` 5) Provide a friendly app name, for example 'Obed and Rally Trials Nov 2022'` +
                ` 6) In the 'App feature options' section, select 'Accept payments' and de-select all other options` +
                ` 7) 'Save' your updates` +
                ` 8) copy the Client ID on the page and paste that into this field.`
      },
      {
        key: 'entryEventName',
        value: `This name will appear at the top of the entry form, e.g. Saturday.`
      },
      {
        key: 'entryEventPrices',
        value: `A comma separated list of the first, second, third, entry fee, without spaces nor currency symbols.`
      },
      {
        key: 'contactName',
        value: `For classes, the instructor; for Show-N-Go's or Auctions or Volunteers, the organizer; for EntryForms, the trial secretary. ` +
                `If multiple names, include 'or' between the names.`
      },
      {
        key: 'contactEmail',
        value: `The email address(es) of the contact person(s). If multiple names, include 'or' between the names.`
      },
      {
        key: 'contactPronoun',
        value: `The pronoun of the contact person(s). If multiple names, use 'them'. ` +
                `For example, the email confirmation might say ` +
                `'The secretary, <contact name>, <contact email>, will confirm your entry. If you have any corrections or other changes, please contact <contact pronoun>.' ` +
                ` and resolve to 'The secretary, Tom Jones, jones@email.com, will confirm your entry. If you have any corrections or other changes, please contact him.' `
      },
      {
        key: 'entryFormAvailableDates',
        value: `The dates which you want the entry form to be available. The Start Date will typically be the Opening Date and Time for the event. ` +
                `The End Date will typically start as the Closing Date and Time, but if the event fills, and you want to cut off online entries, then ` +
                `you may choose to update the End Date and prevent further online entries from being made.`
      },
      {
        key: 'entryFormDataJumpHt',
        value: `The class has no jumps, but the Jump Ht will be used to order small-to-tall or tall-to-small.`
      },
      {
        key: 'serviceFee',
        value: `The fee, in addition to the entry/registration fee, charged to cover the cost of the online service and/or the payment service. `
      },
      {
        key: 'serviceFeeBasis',
        value: `When a service fee is collected, is it per dog or per entrant. ` +
        `For example, for an entry form, you may want to collect the fee for each dog entered, even when it's by the same entrant via the same session. ` +
        `Alternatively, you may want to charge the service fee for the entrant/session regardless of the number of dogs being entered. `
      },
      {
        key: 'userProfileViewEventAdmin',
        value: `If you are just creating a profile to manage your address and dogs for online entries, then you have no need to become an Event Administrator. ` +
          `Event Administrators can create event-specific pages, activate/de-activate the pages, and manage their own events in a self-service manner. ` + 
          `If you are interested in using TFlet Services to host an event, please use the Contact Us button at the bottom of the page to inquire further.`
      },
      {
        key: 'userProfileViewEmail',
        value: `This value is the email you used to log in. If you need to change it, please use the Contact Us information to inquire as to changing your email.`
      },
      {
        key: 'userProfileViewEventManagement',
        value: `Events can be created/added or deleted using the Configuration page.`
      }
    ];

    const index = _.findIndex(subjectMap, val => val.key === subject);
    const text = index > -1 ? subjectMap[index].value : '';

    this.setState({ text })
  }

  handleCloseDialog(e: any, action: string) {
    e.preventDefault();
    this.setState({ open: false });
  }

  showDialog(res: any) {
    this.setState({ open: true });
  }

  render() {
    return (
      <>
        <Tooltip title="More Info">
          <IconButton 
            color="primary" 
            aria-label="edit"
            component="span"
            onClick={(e: any) => this.showDialog(e)}>
            <HelpOutlineIcon style={{ fontSize: 18 }} />
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={(e) => this.handleCloseDialog(e, 'dismiss')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Help</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span>{this.state.text}</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={(e) => this.handleCloseDialog(e, 'dismiss')}
              color="primary">
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default HelpDialog
