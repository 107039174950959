import React, { useState } from 'react';
import './secretaryResults.css';
import {AppBar, Autocomplete, Button, Container, FormControl, Grid, TextField } from '@mui/material';
import { SecretaryScoringRecord } from '../../../models/SecretaryScoringRecord';
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';

interface ISecretaryResults {
  eventId: string
}

export default function SecretaryResults(props: ISecretaryResults) {

  const [ isLoading, setIsLoading ] = useState(false);
  const [ isValidatingPin, setIsValidatingPin ] = useState(false);
  const [ isAgility, setIsAgility ] = useState(false);
  const [ isObedience, setIsObedience ] = useState(false);
  const [ isRally, setIsRally ] = useState(false);
  const [ clubName, setClubName ] = useState('');
  const [ pin, setPin ] = useState('');
  const [ pinValid, setPinValid ] = useState(false);
  const [ scores, setScores ] = useState<SecretaryScoringRecord[]>([]);
  const [ unfilteredScores, setUnfilteredScores ] = useState<any[]>([]);
  const [ filteredScores, setFilteredScores ] = useState<any[]>([]);
  const [ filters, setFilters ] = useState<any[]>([]);
  const [ eventInfo, setEventInfo ] = useState<{day: string, eventType: string }[]>([]);
  const [ daysFilter, setDaysFilter ] = useState<any[]>([]);
  const [ typesFilter, setTypesFilter ] = useState<any[]>(['Scores']);
  const [ classFilter, setClassFilter ] = useState<string[]>([]);
  const [ daySelected, setDaySelected ] = useState('All');
  const [ typeSelected, setTypeSelected ] = useState('Scores');
  const [ classSelected, setClassSelected ] = useState('All');
  const [ searchField, setSearchField ] = useState('');
  const [ lastRefreshed, setLastRefreshed ] = useState(new Date().toISOString());

  const getScores = async (day: string, abbreviatedType: string) => {
    setIsLoading(true);
    const eventInfoIndex = _.findIndex(eventInfo, val => val.day === day);
    const eventType = eventInfoIndex === -1 ? 'All' : eventInfo[eventInfoIndex].eventType;
    const dataRsp = await axios.get(`/api/secretaryResults/${pin}`, { params: { day, eventType, type: abbreviatedType }});
    setUnfilteredScores(dataRsp.data);
    setFilteredScores(dataRsp.data);
    setScores(dataRsp.data);
    setLastRefreshed(new Date().toISOString());
    setIsLoading(false);
  }

  const submitPin = async (event: any) => {
    event.preventDefault();
    setIsValidatingPin(true);
    setScores([]);
    setUnfilteredScores([]);
    const filtersRsp: any = await axios.get(`/api/secretaryResults/filters/${pin}`);
    if (!filtersRsp.data.error && filtersRsp.data.length > 0) {
      setFilters(filtersRsp.data);
      setClubName(_.get(filtersRsp.data[0], 'clubName', ''));
      const days: string[] = ['All'];
      filtersRsp.data.forEach((e: {day: string, clubName: string, eventDate: string, eventType: string, classes: string[]}) => {
        days.push(e.day);
        const newEventInfo = eventInfo;
        newEventInfo.push({day: e.day, eventType: e.eventType });
        setEventInfo(newEventInfo);
        if (e.eventType === 'Agility') {
          setIsAgility(true);
        }
        if (e.eventType === 'Obedience') {
          setIsObedience(true);
          setTypesFilter(['Scores', 'Highest In Trial', 'Highest Combined'])
          if (e.classes.indexOf('Pref Nov') > -1) {
            setTypesFilter(['Scores', 'Highest In Trial', 'Highest Combined', 'Pref Highest In Trial', 'Pref Highest Combined'])
          }
        }
        if (e.eventType === 'Rally') {
          setIsRally(true);
          setTypesFilter(['Scores', 'Highest Triple', 'Highest Combined'])
        }
      });
      setDaysFilter(days);
  
      let day = days[1];
      const today = moment(new Date()).format('YYYY-MM-DD');
      const index = _.findIndex(filtersRsp.data, (val: any) => val.eventDate === today);
  
      if (index > -1) {
        day = filtersRsp.data[index].day;
      } 
      setDaySelected(day);
      setTypeSelected('Scores');
      setClassFilter([ 'All', ..._.filter(filtersRsp.data, val => val.day === day)[0].classes]);
      setSearchField('');
      
      setPinValid(true);
      getScores(day, getAbbreviatedType(typeSelected));
    }
    setIsValidatingPin(false);
  }

  const getAbbreviatedType = (val: string) => {
    return val === 'Highest In Trial' ? 'hit' : val === 'Pref Highest In Trial' ? 'phit' : val === 'Highest Combined' ? 'hc' : val === 'Pref Highest Combined' ? 'phc' : val === 'Highest Triple' ? 'ht' : 'scores';
  }

  const handleChangeDay = async (newValue: string) => {
    if (!newValue || newValue === 'All') {
      setClassSelected('All');
      setClassFilter(['All']);
      setDaySelected('All');
      setSearchField('');
      setTypeSelected('Scores');
      getScores('All', 'scores');
    } else {
      setClassFilter([ 'All', ..._.filter(filters, val => val.day === newValue)[0].classes]);
      setScores(_.filter(unfilteredScores, val => val.day === newValue));
      setDaySelected(newValue);
      getScores(newValue, getAbbreviatedType(typeSelected));
      setSearchField('');
    }
  }

  const handleChangeType = (newValue: string) => {
    if (newValue === 'Scores') {
      setClassSelected('All');
    }
    setTypeSelected(newValue);
    getScores(daySelected, getAbbreviatedType(newValue));
  }

  const handleChangeClass = (newValue: string) => {
    if (!newValue || newValue === 'All') {
      setClassSelected('All');
      setScores(_.filter(unfilteredScores, val => val.day === daySelected));
      setFilteredScores(_.filter(unfilteredScores, val => val.day === daySelected));
      setSearchField('');
    } else {
      setScores(_.filter(unfilteredScores, val => val.day === daySelected && newValue.indexOf(val.class) > -1));
      setFilteredScores(_.filter(unfilteredScores, val => val.day === daySelected && newValue.indexOf(val.class) > -1));
      setClassSelected(newValue);
    }
  }

  const handleChangeSearchField = (newValue: string) => {
    if (!newValue || newValue === '') {
      setScores(filteredScores);
      setSearchField('');
    } else {
      setScores(_.filter(filteredScores, val => val.callName.toLowerCase().startsWith(newValue.toLowerCase())));
      setSearchField(newValue);
    }
  }

  const getLoadingMessage = ()  => {
    if (typeSelected === 'Highest Triple') {
      return 'High Triple results';
    }
    if (typeSelected.indexOf('Highest Combined') > -1) {
      return 'HC results';
    }
    if (typeSelected.indexOf('Highest In Trial') > -1) {
      return 'HIT results';
    }
    return 'the scores';
  }

  return (
    <div>
      <AppBar position="sticky" className="noprint">
        <Container maxWidth="xl"  className="app-secretary-results-header">
          <div className="app-secretary-results-header-text">Unofficial Results</div>
        </Container>
      </AppBar>
      <div>&nbsp;</div>
      <Grid container justifyContent="center" alignItems="center" alignContent="center">
        <Grid item md={2} xs={4} p={1}>
          <TextField fullWidth
            id="secretary-results-pin"
            value={pin}
            onChange={(e: any) => { setPin(e.target.value.trim()); setPinValid(false); }}
            label="4-digit PIN" />
        </Grid>
        <Grid item md={2} xs={4} p={1}>
          <Button 
            id="secretary-results-submit-button"
            color="primary" 
            variant="contained"
            disabled={ pin.length < 4 || pinValid }
            style={{textTransform: 'none'}}
            onClick={(e: any) => submitPin(e)}>
            Submit PIN
          </Button>
        </Grid>
        <Grid item md={2} xs={4} p={1}>
          <Button 
            id="secretary-results-refresh-button"
            color="primary" 
            variant="contained"
            disabled={ !pinValid }
            style={{textTransform: 'none'}}
            onClick={async (e: any) => await getScores(daySelected, getAbbreviatedType(typeSelected))}>
            Refresh
          </Button>
        </Grid>
        <Grid item md={1} xs={6} p={1}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id="secretary-results-day-select"
              options={daysFilter}
              value={daySelected}
              disabled={!pinValid}
              disableClearable
              onChange={(e: any, newValue: any) => handleChangeDay(newValue)}
              renderInput={(params) => <TextField {...params} label="Day" />} />
          </FormControl>
        </Grid>
        { (isObedience || isRally) && daySelected !== 'All' ?
          <Grid item md={2} xs={6} p={1}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="secretary-results-type-select"
                options={typesFilter}
                value={typeSelected}
                disabled={!pinValid}
                disableClearable
                onChange={(e: any, newValue: any) => handleChangeType(newValue)}
                renderInput={(params) => <TextField {...params} label="Query Type" />} />
            </FormControl>
          </Grid>
          :<></>
        }
        { typeSelected === 'Scores' ?
          <Grid item md={2} xs={6} p={1}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="secretary-results-class-select"
                options={classFilter}
                value={classSelected}
                disableClearable
                disabled={!pinValid}
                onChange={(e: any, newValue: any) => handleChangeClass(newValue)}
                renderInput={(params) => <TextField {...params} label="Class" />} />
            </FormControl>
          </Grid>
          : <></>
        }
        <Grid item md={12} xs={12} p={1}>
          <TextField 
            id={'secretary-results-search'}
            variant="outlined"
            size="small"
            value={searchField}
            disabled={!pinValid}
            onChange={(e: any) => handleChangeSearchField(e.target.value)}
            label="Search Call Name" />
        </Grid>
      </Grid>
      { isLoading || isValidatingPin ?
        <>
          {
            isValidatingPin ?
            <div>Validating PIN please be patient</div>
            :
            <div>Loading { getLoadingMessage() }....</div>
          }
        </>
        :
        <>
        <Grid container justifyContent="center" alignItems="center" alignContent="center">
        <Grid item md={12} xs={12} id="secretary-results-club-name">{ clubName }</Grid>
        <Grid item md={2} xs={12} id="secretary-results-results-count">Displaying { scores.length === 0 ? 'no' : scores.length } result{ scores.length === 0 || scores.length > 1 ? 's' : ''}.</Grid>
        <Grid item md={4} xs={12} id="secretary-results-last-refresh">Last refreshed at { moment(lastRefreshed).tz('America/New_York').format('MMM DD, YYYY hh:mm a') }</Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" alignContent="center">
        { scores.map((scoreCard: any, index: number) => 
          <Grid container key={`secretary-results-card-${index}`} className='app-secretary-results-card'>
            <Grid item md={2} xs={6} id={`secretary-results-card-call-name-${index}`}>CallName: { scoreCard.callName }</Grid>
            <Grid item md={2} xs={6} id={`secretary-results-card-call-name-${index}`}>Armband: { scoreCard.armband }</Grid>
            <Grid item md={3} xs={6} id={`secretary-results-card-owner-${index}`}>Owner: { scoreCard.owner }</Grid>
            <Grid item md={1} xs={6} id={`secretary-results-card-day-${index}`}>Day: { scoreCard.day }</Grid>
            <Grid item md={2} xs={6} id={`secretary-results-card-class-${index}`}>Class: { scoreCard.class }</Grid>
            { isAgility ? 
              <Grid item md={2} xs={6} id={`secretary-results-card-jump-ht-${index}`}>JumpHt: { scoreCard.jumpHt }</Grid>
              :<></>
            }
            <Grid item md={2} xs={6} id={`secretary-results-card-score-${index}`}>Score: { scoreCard.score }</Grid>
            { isAgility || isRally ? 
              <Grid item md={2} xs={6} id={`secretary-results-card-time-${index}`}>Time: { scoreCard.time === 0 ? 'No Time' : _.get(scoreCard, 'time', 0).toFixed(2) }</Grid>
              : <></>
            }
            { scoreCard.placement ? 
              <Grid item md={1} xs={6} id={`secretary-results-card-place-${index}`}>Place: { scoreCard.placement }</Grid>
              : <></>
            }
            { scoreCard.isTop25 ? 
              <Grid item md={1} xs={6} id={`secretary-results-card-top-25-${index}`}>Top 25%: Yes</Grid>
              : <></>
            }
            { scoreCard.fastQ ? 
              <Grid item md={1} xs={6} id={`secretary-results-card-fast-q-${index}`}>Qualified</Grid>
              : <></>
            }
          </Grid>
        )}
      </Grid>
        </>
      }
      <div>&nbsp;</div>
    </div>
  )
}
