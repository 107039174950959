import React, { useState } from 'react';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setData } from '../../../redux/reducers/eventDataSlice';
import axios from 'axios';
import _ from 'lodash';
import './configViewRoster.css';
import { DataInfo } from '../../../models/DataInfo';
import { ReservationInfo } from '../../../models/ReservationInfo';

interface IConfigViewRoster {
  onChange: () => void
}

export default function ConfigViewRoster(props: IConfigViewRoster) {

  const { email, jwtToken } = useAppSelector((state: any) => state.registrant);
  const { _id, template, maxStudents } = useAppSelector((state: any) => state.eventConfig);
  const { data } = useAppSelector((state: any) => state.eventData);
  const dispatch = useAppDispatch();

  const [ isDirty, setIsDirty ] = useState(false);
  const [ msg, setMsg ] = useState('');

  const handleClickSaveRoster = () => {
    const reservations: ReservationInfo[] = [];
    data.forEach((dataInfo: DataInfo, dataIndex: number) => {
      dataInfo.reservations.forEach((reservation: ReservationInfo) => {
        reservations.push({ 
          ...reservation, 
          slotIndex: dataIndex, 
          registrantFirstName: dataInfo.registrant.firstName, 
          registrantLastName: dataInfo.registrant.lastName, 
          registrantEmail: dataInfo.registrant.email 
        });
      });
    });
    return axios.post(`/api/protected/data/${_id}`, reservations, { headers: { Authorization: `Bearer ${jwtToken}` }, params: { email }})
    .then((dataRsp: any) => {
      if (dataRsp.ok) {
        const updatedData = dataRsp.data.data;
        dispatch(setData(updatedData)); 
      }
      setIsDirty(false);
    });
  }

  const handleChangeParticipantField = (e: any, dataIndex: number, fieldName: string) => {
    let dogName = '';
    const updatedData: DataInfo[] = _.cloneDeep(data);
    if (fieldName === 'firstName') {
      updatedData[dataIndex].registrant.firstName = e.target.value;
    } 
    if (fieldName === 'lastName') {
      updatedData[dataIndex].registrant.lastName = e.target.value;
    } 
    if (fieldName === 'email') {
      updatedData[dataIndex].registrant.email = e.target.value;
    }
    if (fieldName === 'dogName') {
      dogName = e.target.value;
      updatedData[dataIndex].reservations[0].dogName = dogName;
    }
    if (updatedData[dataIndex].reservations.length === 0) {
      updatedData[dataIndex].reservations.push({
        dogName,
        jumpHt: "",
        breed: "",
        sessionIndex: 0,
        slotIndex: 0,
        sortValues: [ 0 ],
        runs: [ 1 ],
        attendance: []
      });
    }
    dispatch(setData(updatedData));
    setIsDirty(true);
    props.onChange();
  }

  const handleClickAddParticipant = () => {
    if (data.length === maxStudents) {
      setMsg(`ERROR: cannot add another student because maxStudents for this class is ${maxStudents}`);
      return;
    }
    const updatedData: DataInfo[] = _.cloneDeep(data);
    const newParticipant: DataInfo = {
      registrant: {
        firstName: '',
        lastName: '',
        email: '',
        allowCookies: false
      },
      reservations: [],
      eventId: '',
      classes: []
    };
    updatedData.push(newParticipant);
    dispatch(setData(updatedData));
    setIsDirty(true);
  }

  const handleClickDeleteParticipant = (dataIndex: number) => {
    const updatedData: DataInfo[] = _.cloneDeep(data);
    const reservation: ReservationInfo = _.cloneDeep(updatedData[dataIndex].reservations[0]);
    if (
      _.get(updatedData[dataIndex], 'registrant.name', '') === '' &&
      _.get(updatedData[dataIndex], 'registrant.email', '') === '' &&
      _.get(updatedData[dataIndex], 'reservations[0].dogName', '') === ''
    ) {
      // deleting a student which was never filled in
      updatedData.splice(dataIndex, 1);
      dispatch(setData(updatedData));
      return;
    }

    reservation.registrantFirstName = _.get(updatedData[dataIndex], 'registrant.firstName', '');
    reservation.registrantLastName = _.get(updatedData[dataIndex], 'registrant.LastName', '');
    reservation.registrantEmail = _.get(updatedData[dataIndex], 'registrant.email', '');
    return axios.delete(`/api/protected/data/${_id}`, { headers: { Authorization: `Bearer ${jwtToken}` }, params: { email }, data: { ...reservation }})
    .then((res: any) => {
      updatedData.splice(dataIndex, 1);
      dispatch(setData(updatedData));
    });
  }

  return (
    <>
      <Grid container justifyContent="center" spacing={1}>
        <Grid key={'roster-header'} item md={12}>
            <h2>Roster</h2>
        </Grid>
        <Grid key={'roster-add'} item md={12}>
          <Button 
            id="configView-roster-add-participant"
            color="primary" 
            variant="contained"
            className="config-view-button"
            style={{textTransform: 'none'}}
            onClick={() => handleClickAddParticipant()}>
            { template === 'Class' ? 'Add Student' : 'Add Participant' }
          </Button>
        </Grid>
      </Grid>
      { data && data.map((dataRecord: DataInfo, dataIndex: number) => (
        <Grid key={'roster-registrant-' + dataIndex} container justifyContent="center" spacing={2}>
          <Grid item md={2} xs={12} p={2}>
            <TextField 
              id={'registrant-' + dataIndex + '-first-name'}
              variant="outlined"
              size="small"
              className={'config-view-roster-field-first-name'}
              value={_.get(dataRecord, 'registrant.firstName', '')}
              onChange={(e) => handleChangeParticipantField(e, dataIndex, 'firstName')}
              label='Handler First Name' />
              <TextField 
              id={'registrant-' + dataIndex + '-last-name'}
              variant="outlined"
              size="small"
              className={'config-view-roster-field-last-name'}
              value={_.get(dataRecord, 'registrant.lastName', '')}
              onChange={(e) => handleChangeParticipantField(e, dataIndex, 'lastName')}
              label='Handler Last Name' />
          </Grid> 
          <Grid item md={3} xs={12}>
            <TextField 
              id={'registrant-' + dataIndex + '-email'}
              variant="outlined"
              size="small"
              className={'config-view-roster-field-email'}
              value={_.get(dataRecord, 'registrant.email', '')}
              onChange={(e) => handleChangeParticipantField(e, dataIndex, 'email')}
              label='Email' />
          </Grid> 
          <Grid item md={2} xs={12}>
            <TextField 
              id={'registrant-' + dataIndex + '-dog-name'}
              variant="outlined"
              size="small"
              className={'config-view-roster-field-dog-name'}
              value={_.get(data[dataIndex], 'reservations[0].dogName', '')}
              onChange={(e) => handleChangeParticipantField(e, dataIndex, 'dogName')}
              label='Dog name' />
          </Grid>
          <Grid item md={1} xs={12}>
            <Tooltip title="Delete participant">
              <IconButton 
                id={'registrant-' + dataIndex + '-delete'}
                key={dataIndex}
                color="primary" 
                aria-label="delete participant"
                component="span"
                onClick={() => handleClickDeleteParticipant(dataIndex)}>
                <DeleteIcon style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ))}
      { msg !== '' ?
        <div>
          <span className="config-view-roster-error-msg">{msg}</span>
          <IconButton 
              id="config-view-roster-msg-clear"
              color="primary" 
              aria-label="clear message"
              component="span"
              onClick={() => setMsg('')}>
              <DeleteIcon style={{ fontSize: 18 }} />
            </IconButton>
        </div>
        : null
      }
      <Grid key={'roster-save'} item md={12}>
        <div className="config-view-roster-save">
          <Button 
            id="configView-roster-save"
            color="primary" 
            variant="contained"
            className="config-view-button"
            style={{textTransform: 'none'}}
            disabled={!isDirty}
            onClick={() => handleClickSaveRoster()}>
            Save Roster
          </Button>
        </div>
      </Grid>
    </>
  )
}
