import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { setCreatedBy, setEventConfig } from './redux/reducers/eventConfigSlice';
import { setData } from './redux/reducers/eventDataSlice';
import { setJwtToken } from './redux/reducers/registrantSlice';
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import './App.css';
import AppFooter from './components/atoms/appFooter/appFooter';
import ClassView from './components/organisms/classView/classView';
import CookiesMessage from './components/atoms/cookiesMessage/cookiesMessage';
import ConfigView from './components/organisms/configView/configView';
import DownloadInfo from './components/organisms/downloadInfo/downloadInfo';
import EntryFormView from './components/organisms/entryFormView/entryFormView';
import HeaderMenuBar from './components/organisms/headerMenuBar/headerMenuBar';
import LoginMessage from './components/atoms/loginMessage/loginMessage';
import RegistrantInfo from './components/molecules/registrantInfo/registrantInfo';
import SecretaryEvents from './components/molecules/secretaryEvents/secretaryEvents';
import SecretaryResults from './components/molecules/secretaryResults/secretaryResults';
import SessionsView from './components/organisms/sessionsView/sessionsView';
import UserProfileView from './components/organisms/userProfileView/userProfileView';
import WelcomeView from './components/organisms/welcomeView/welcomeView';
import { SessionConfig } from './models/SessionConfig';
import { ReservationInfo } from './models/ReservationInfo';

export default function App() {
 
  const { _id, url, title, contactName, contactEmail, contactPronoun, createdBy, sessions, files, notices, dates, active, template, maxStudents, signupGranularity, startAvailableDate, endAvailableDate, secretaryEvents } = useAppSelector((state: any) => state.eventConfig);
  const { firstName, lastName, email, isAdmin, isAuth, jwtToken, administerEventId } = useAppSelector((state: any) => state.registrant);
  const { user, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const dispatch = useAppDispatch();

  const [ loading, setLoading ] = useState(true);
  const [ dataFetchTimeISO, setDataFetchTimeISO ] = useState('');
  const [ eventId ] = useState(window.location.pathname === '/' ? '/home' : window.location.pathname)
  const [ configView, setConfigView ] = useState(false);
  const [ dialog, setDialog ] = useState({
    open: false,
    status: '',
    dog: '',
    session: -1,
    slot: -1,
    update: false,
    delete: false
  });

  useEffect(() => {
    async function fetchData() {
      try {
        if (eventId === '/no-event' || eventId === '/configurations') {
          setLoading(false);
          return;
        }
        const configRsp = await axios.get(`/api/config${eventId === '/home' ? '/events' : eventId }`);
        let options: any = { params: { email }, headers:  { Authorization: `Bearer ${jwtToken}` }};
        let url = `/api/protected/data${eventId}`;
        if (!isAdmin || !jwtToken) {
          url = `/api/data${eventId}`;
          delete options.headers;
        }
        const dataRsp = await axios.get(url, options);

        const data: any = _.get(dataRsp, 'data.data', []);
        dispatch(setCreatedBy(_.get(configRsp, 'data.createdBy', '')));
        const sessions: any[] = [];
        _.get(configRsp, 'data.sessions', []).forEach((session: any, sessionIndex: number) => {
          const reservations: any[] = [];
          const defaultSortValues = session.runs.map(() => 100);

          const numberSlotsOffered = session.numberSlotsOffered ? session.numberSlotsOffered : _.get(configRsp, 'data.maxStudents', 0);
          for (let i=0; i < numberSlotsOffered; i++) {
            reservations.push({ id: i, status: 'Available', registrantName: '', registrantEmail: '', dogName: '', jumpHt: '', sortValues: defaultSortValues, edit: false })
          }
          data.forEach((result: any) => {
            result.reservations.forEach((reservation: any) => {
              if (reservation.sessionIndex === sessionIndex) {
                reservations[reservation.slotIndex].status = 'Reserved';
                reservations[reservation.slotIndex].dogName = reservation.dogName;
                reservations[reservation.slotIndex].jumpHt = reservation.jumpHt;
                reservations[reservation.slotIndex].sortValues = reservation.sortValues;
              }
            });
          });
          sessions.push({
            name: session.name,
            numberSlotsOffered: session.numberSlotsOffered,
            sortOrder: session.sortOrder,
            sortField: session.sortField,
            pricePerRun: session.pricePerRun,
            runs: session.runs,
            selected: sessionIndex === 0 ? true : false,
            reservations
          });
        });
        setLoading(false);
        dispatch(setEventConfig({
          _id: _.get(configRsp, 'data._id', ''),
          url: `https://${window.location.host}/${configRsp.data._id}`,
          active: _.get(configRsp, 'data.active', false),
          title: _.get(configRsp, 'data.title', ''),
          createdBy: _.get(configRsp, 'data.createdBy', ''),
          contactName: _.get(configRsp, 'data.contactName', ''),
          contactEmail: _.get(configRsp, 'data.contactEmail', ''),
          contactPronoun: _.get(configRsp, 'data.contactPronoun', ''),
          notices: _.get(configRsp, 'data.notices', []),
          startAvailableDate: _.get(configRsp, 'data.startAvailableDate', ''),
          endAvailableDate: _.get(configRsp, 'data.endAvailableDate', ''),
          template: _.get(configRsp, 'data.template', ''),
          maxStudents: _.get(configRsp, 'data.maxStudents', 0),
          signupGranularity: _.get(configRsp, 'data.signupGranularity', 'session'),
          anonymousOnly: _.get(configRsp, 'data.anonymousOnly', false),
          files: _.get(configRsp, 'data.files', []),
          dates: _.get(configRsp, 'data.dates', []),
          entryEvents: _.get(configRsp, 'data.entryEvents', []),
          secretaryEvents: _.get(configRsp, 'data.secretaryEvents', []),
          sessions,
          activeSessionIndex: sessions.length > 0 ? 0 : -1,
          serviceFee: _.get(configRsp, 'data.serviceFee', 0),
          serviceFeeBasis: _.get(configRsp, 'data.serviceFeeBasis', 'owner'),
        }));
        dispatch(setData(data));
        setDataFetchTimeISO(new Date().toISOString());
      } catch(err: any) {
        setLoading(false);
        console.log('get config/data err=' + JSON.stringify(err, null, 2));
      }
    }
    fetchData();
  }, [eventId, dispatch, jwtToken, isAdmin, email, signupGranularity, firstName]);

  useEffect(() => {
    if (eventId === '/configurations' && isAdmin && isAuth) {
      setConfigView(true);
    }
  }, [ eventId, isAdmin, isAuth ]);

  useEffect(() => {
    async function fetchData() {
      try {
        let options: any = { headers:  { Authorization: `Bearer ${jwtToken}` }, params: { email }};
        let url = `/api/protected/data${administerEventId ? administerEventId : ''}`;
        if (!isAdmin) {
          url = `/api/data${eventId}`;
          delete options.headers;
        }
        const dataRsp = await axios.get(url, options);
        const data: any = _.get(dataRsp, 'data.data', []);
        dispatch(setData(data));
      } catch(err: any) {
        console.log('app: get data err=' + JSON.stringify(err, null, 2));
      }
    }
    if (_id) {
      fetchData();
    }
  }, [_id, administerEventId, dispatch, email, eventId, isAdmin, jwtToken]);

  useEffect(() => {
    async function fetchData() {
      try {
        let token: string|null|undefined = '';

        token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://events/api',
            scope: 'read:profile',
          }
        });
        dispatch(setJwtToken(token ? token : ''));
      } catch(err: any) {
        /* istanbul ignore next */
        console.log('app: get jwt token error/cancel')
      }
    }
    fetchData();
  }, [dispatch, email, getAccessTokenSilently, getAccessTokenWithPopup, user]);


  const saveConfigToDB = (newValues: any) => {
    // newValues are objects, e.g. dates, that may not have been propogated to the store yet
    const trimmedSessions: SessionConfig[] = _.cloneDeep(sessions);
    trimmedSessions.forEach((session: any) => {
      delete session.selected;
      delete session.reservations;
    });

    const updatedObj: any = {
      _id: _id.replace('/', ''),
      url,
      active,
      title,
      createdBy,
      contactName,
      contactEmail,
      contactPronoun,
      notices,
      sessions: trimmedSessions,
      files,
      secretaryEvents,
      template,
      maxStudents,
      signupGranularity,
      startAvailableDate,
      endAvailableDate,
      dates,
      ...newValues
    }
    return axios.post(`/api/protected/config/${updatedObj._id}`, updatedObj, { params: { email } , headers: { Authorization: `Bearer ${jwtToken}` }})
  }

  const handleCloseDialog = () => {
    setDialog({ open: false, status: '', dog: '', session: -1, slot: -1, update: false, delete: false });
  }

  const getReservations = (registrantEmail: string) => {
    let options: any = { headers:  { Authorization: `Bearer ${jwtToken}` }, params: { email: registrantEmail }};
    let url = `/api/protected/data${administerEventId}`;
    if (!isAdmin) {
      url = `/api/data${eventId}`;
      delete options.headers;
    }
    return axios.get(url, options)
    .then((res: any) => {
      dispatch(setData(res.data.data));
    });
  }

  const handleAttendanceChange = (reservation: ReservationInfo) => {
    return axios.post(`/api/protected/data${administerEventId}`, [ reservation ], { headers: { Authorization: `Bearer ${jwtToken}` }, params: { email }})
    .then((res: any) => {
      // get the latest reservations, in case others have reserved slots since previous refresh
      return getReservations(email);
    });
  }

  const handleClassStatusChange = (newValue: any) => {
    saveConfigToDB({ dates: [ ...newValue ]});
  }

  const handleSessionSortChange = (updatedSessions: SessionConfig[]) => {
    const trimmedSessions = _.cloneDeep(updatedSessions);
    trimmedSessions.forEach((session: SessionConfig) => {
      delete session.selected;
      session.reservations = [];
    });
    saveConfigToDB({ sessions: trimmedSessions });
  }

  const handleReservationSortChange = (reservations: ReservationInfo[]) => {
    return axios.post(`/api/protected/data${administerEventId}`, reservations, { headers: { Authorization: `Bearer ${jwtToken}` }, params: { email }})
    .then((res: any) => {
      // get the latest reservations, in case others have reserved slots since previous refresh
      return getReservations(email);
    });
  }

  const saveReservation = (newValue: ReservationInfo) => {
    const registrantEmail = email !== '' ? email : newValue.registrantEmail;
    let options: any = { headers: { Authorization: `Bearer ${jwtToken}` }, params: { email: registrantEmail }};

    let url = `/api/protected/data${administerEventId}`;
    if (!isAdmin) {
      url = `/api/data${eventId}`;
      delete options.headers;
    }
    return axios.post(url, [ newValue ], options)
      .then((res: any) => {
        const dataIndex = 0;
        // TODO: this assumes no more than one reservation per registrant per dog per session, if Tracy registers Fido twice for Session 1, then the 
        // incorrect reservationIndex will be derived. the only way to fix this, since the slotIndex could be changed on the server, is a uuid per reservation
        const reservationIndex = _.get(res, `data[${dataIndex}]`, null) === null ? -1 : _.findIndex(res.data[dataIndex].reservations, (val: ReservationInfo) => val.dogName === newValue.dogName && val.sessionIndex === newValue.sessionIndex);
        showDialog({
          data: {
            ok: res.data.ok,
            dogName: reservationIndex === -1 ? null : res.data[dataIndex].reservations[reservationIndex].dogName,
            sessionIndex: reservationIndex === -1 ? null : res.data[dataIndex].reservations[reservationIndex].sessionIndex,
            slotIndex: reservationIndex === -1 ? null : res.data[dataIndex].reservations[reservationIndex].slotIndex
          }
        });
        // get the latest reservations, in case others have reserved slots since previous refresh
        return getReservations(registrantEmail);
      });
  }

  const cancelReservation = (cancelledReservation: ReservationInfo) => {
    let options: any = { headers: { Authorization: `Bearer ${jwtToken}` }, params: { email }, data: { ...cancelledReservation }};
    let url = `/api/protected/data${administerEventId}`;
    if (!isAdmin) {
      url = `/api/data${eventId}`;
      delete options.headers;
    }
    return axios.delete(url, options)
      .then((res: any) => {
        showDialog({ ...res, delete: true });
        // get the latest reservations, in case others have reserved slots since previous refresh
        return getReservations(email);
      })
  }

  const showDialog = (res: any) => {
    setDialog({
      status: res.data.ok ? 'Success' : 'Failed',
      open: true,
      dog: res.data.dogName !== null ? res.data.dogName : 'unknown',
      session: res.data.sessionIndex !== null ? res.data.sessionIndex + 1 : 'unknown',
      slot: res.data.slotIndex !== null ? res.data.slotIndex + 1 : 'unknown',
      update: res.data.createdDate === res.data.updatedDate,
      delete: res.delete ? res.delete : false,
    });
  }

  const showContactUsDialog = (status: string) => {
    setDialog({
      status,
      open: true,
      dog: '',
      session: 0,
      slot: 0,
      update: false,
      delete: false
    });
  }

  return (
    <div className="App">
      { loading ? 
        <div className="app-loading">Loading...</div>
      :
      <>
        <HeaderMenuBar />
        { !['/events','/results', '/home', '/configurations'].includes(eventId) ?
          <h2 className="noprint">{title}</h2>
          : <div>&nbsp;</div>
        }
        { !isAuth && eventId === '/home'  ? 
          <LoginMessage />
          : null
        }
        { isAuth && firstName === '' && eventId !== '/userProfile' ?
          <Link href="/userProfile" style={{ textDecoration: 'none' }} underline='none'>
              <Typography textAlign="center">Setup your profile</Typography>
          </Link>
          : null
        }
        { eventId === '/products' ?
          <WelcomeView />
          : null
        }
        { eventId !== '/no-event' && eventId !== '/configurations' && files.length > 0 ?
          <DownloadInfo eventId={eventId} files={files} dataFetchTimeISO={dataFetchTimeISO}></DownloadInfo>
          : null
        }

        { eventId !== '/no-event' && eventId !== '/configurations' && notices.length > 0 ?
          <>
            <div id="app-notices-header">Attention</div>
            <Grid container alignItems="center" alignContent="center"></Grid>
              { notices.map((val: string, noticesIndex: number) => (
                 <Grid item md={12} xs={12} p={1} className="app-notice" key={`app-notice-${noticesIndex}`}>{ val }</Grid>
            ))}
          </>
          : null
        }
        { eventId !== '/no-event' && eventId !== '/products' && eventId !== '/configurations' && eventId !== '/userProfile' && !active ?
          <>
          { createdBy === '' ?
            <div className="app-event-inactive">This event is invalid. Confirm you have the correct URL.</div>
          : <div className="app-event-inactive">This event is currently inactive. Contact the organizer for additional information about {eventId}.</div>
          }
          </>
        :
          <>
          {/*
          { !anonymousOnly ?
            <div className="noprint">
              <RegistrantInfo eventId={eventId} />
            </div>
            : null
          }
          */}
          </>
        }
        { eventId !== '/configurations' && (email === '' || firstName === '' || lastName === '') ?
          <>
            { active && template === 'SecretaryResults' ? 
              <>
                <SecretaryResults eventId={eventId} />
              </>
              : null
            }
            { active && template === 'EntryForm' ? 
              <>
                { !(isAuth && email === createdBy) && (startAvailableDate !== '' && new Date().toISOString() < startAvailableDate) ?
                  <div id="app-entry-start-available-msg">
                    Online entry is not available until the Opening Date, { moment(startAvailableDate).tz('America/New_York').format('MMM DD, YYYY hh:mm a') + ' ET' }.
                  </div>
                  : <>
                      {  endAvailableDate !== '' && new Date().toISOString() > endAvailableDate ?
                        <div id="app-entry-end-available-msg">
                          Online entry is not available after the Closing Date, { moment(endAvailableDate).tz('America/New_York').format('MMM DD, YYYY hh:mm a') + ' ET' }.
                        </div>
                        : <>
                            <RegistrantInfo eventId={eventId} />

                            <div className="info-data">
                              Please enter your name and email address to start the Trial Entry process
                            </div>
                          </>
                      }
                    </>
                }
              </>
              : null
            }

            {  active && template === 'Show-N-Go' && sessions.length > 0 ?
              <>
                <RegistrantInfo eventId={eventId} />

                <div className="info-data">
                      Please enter your name and email address to view, add, change, or cancel your reservation(s).
                      If you don't recall the exact name or email you used previously, email the contacts listed in the flyer.
                </div>
                <SessionsView eventId={eventId} 
                  saveReservation={saveReservation}
                  cancelReservation={cancelReservation} />
              </>
              :
              null
            }
          </>
          :
          <Grid container alignItems="center" alignContent="center" justifyContent="center">
            { eventId !== '/no-event' && ['Show-N-Go'].includes(template) && !isAdmin ?
              <Grid item md={6} xs={12} p={1}>
                <div id="app-email-confirm-msg">
                  <span id="app-email-confirm-msg-prefix">IMPORTANT! </span>
                  <span id="app-email-confirm-msg">
                    You identified yourself as <strong>{firstName} {lastName}</strong> with email <strong>{email}</strong>. 
                    If you return later, use these to view/edit/cancel your reservations. If this is not you, use 'Clear Name' below to start over.
                    If you don't see your previous registration or you don't recall the exact name or email you used previously, 
                    feel free to email the contacts listed in the flyer.
                  </span>
                </div>
              </Grid>
              : null
            }
            { eventId !== '/no-event' && eventId !== '/configurations' && ['Show-N-Go'].includes(template) && !configView && sessions.length > 0 ?
              <SessionsView 
                eventId={eventId} 
                saveReservation={saveReservation}
                cancelReservation={cancelReservation}/>
              : null
            }

            { eventId !== '/no-event' && ['Class', 'Ad-Hoc'].includes(template) && !configView ?
              <ClassView 
                onStudentAttendanceStatusChange={handleAttendanceChange}
                onClassStatusChange={handleClassStatusChange}
              />
              : null
            }

            { eventId !== '/no-event' && ['EntryForm'].includes(template) && !configView ?
              <>
                { !(isAuth && email === createdBy) && (startAvailableDate !== '' && new Date().toISOString() < startAvailableDate) ?
                  <div id="app-entry-start-available-msg">
                    Online entry is not available until the Opening Date, { moment(startAvailableDate).tz('America/New_York').format('MMM DD, YYYY hh:mm a') + ' ET' }.
                  </div>
                  : <>
                      {  endAvailableDate !== '' && new Date().toISOString() > endAvailableDate ?
                        <div id="app-entry-end-available-msg">
                          Online entry is not available after the Closing Date, { moment(endAvailableDate).tz('America/New_York').format('MMM DD, YYYY hh:mm a') + ' ET' }.
                        </div>
                        : <>
                            <RegistrantInfo eventId={eventId} />
                            <EntryFormView />
                          </>
                      }
                    </>
                }
              </>
              :null
            }
          </Grid>
        }
        { eventId === '/userProfile'  ?
          <UserProfileView />
          : null
        }
        { eventId === '/configurations'  ?
          <ConfigView 
            eventId={eventId}
            dataFetchTimeISO={dataFetchTimeISO}
            handleSessionSortChange={handleSessionSortChange}
            handleReservationSortChange={handleReservationSortChange}
            handleAttendanceChange={handleAttendanceChange}
            handleClassStatusChange={handleClassStatusChange}
            saveReservation={saveReservation}
            cancelReservation={cancelReservation}
          />
          : null
        }
        { eventId === '/home' || eventId === '/events'  ?
          <SecretaryEvents eventId={'/events'} />
          : null
        }
      </>
      }
      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.status}</DialogTitle>
        <DialogContent>
          { dialog.status === 'Contact Us' ? 
            <>
              <DialogContentText id="alert-dialog-description-line-1">
                If you are having issues with the web site performance or behavior, please email tfletservices@gmail.com with a description of the issue you are experiencing.
              </DialogContentText>
              <DialogContentText id="alert-dialog-description-line-2">
                If you have questions about the information on the web site, please email { createdBy } with your questions.
              </DialogContentText>
            </>
            :
            <DialogContentText id="alert-dialog-description">
              Reservation for session {dialog.session}{ signupGranularity === 'slot' ? `, slot ${dialog.slot}` : '' } {dialog.delete ? 'deleted' : 'completed' } for {dialog.dog}
              {dialog.status === 'Failed' ?
                <span>Please try again</span>
                : null
              }
            </DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          <Button id="dialog-dismiss" onClick={handleCloseDialog} color="primary" autoFocus>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
      
      <CookiesMessage />

      <AppFooter showDialog={showContactUsDialog} />
    </div>
  );
}
