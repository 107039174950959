import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setEntryEvents } from '../../../redux/reducers/eventConfigSlice';
import './configViewEntryEvents.css';
import _ from 'lodash';
import { EntryEventConfig } from '../../../models/EntryEventConfig';
import HelpDialog from '../../atoms/helpDialog/helpDialog';
import axios from 'axios';

interface IConfigViewEntryEvents {
  onChange: () => void
}

export default function ConfigViewEntryEvents(props: IConfigViewEntryEvents) {

  const [ obedienceClasses, setObedienceClasses ] = useState<{name: string, type: string, isOffered: boolean}[]>([]);
  const [ rallyClasses, setRallyClasses ] = useState<{name: string, type: string, isOffered: boolean}[]>([]);
  const [ eventClassesAvailable, setEventClassesAvailable ] = useState<{name: string, type: string, isOffered: boolean}[][]>([]);
  const { entryEvents } = useAppSelector((state: any) => state.eventConfig);

  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetchData() {
      try {
        const akcObedienceClassesRsp: any[] = await axios.get(`/api/constants/akc-obedience-classes`);
        const updatedObedienceClasses: any[] = [];
        _.get(akcObedienceClassesRsp, 'data.classes', []).forEach((obedienceClass: any) => {
          updatedObedienceClasses.push({ name: obedienceClass.name, type: obedienceClass.type, isOffered: false });
        });
        setObedienceClasses(updatedObedienceClasses);

        const akcRallyClassesRsp: any[] = await axios.get(`/api/constants/akc-rally-classes`);
        const updatedRallyClasses: any[] = [];
        _.get(akcRallyClassesRsp, 'data.classes', []).forEach((rallyClass: any) => {
          updatedRallyClasses.push({ name: rallyClass.name, type: rallyClass.type, isOffered: false });
        });
        setRallyClasses(updatedRallyClasses);
      } catch(err: any) {
        console.log('get constants akc-obedience-classes/akc-rally-classes err=' + JSON.stringify(err, null, 2));
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const updatedEventClassesAvailable: {name: string, type: string, isOffered: boolean}[][] =[];
    entryEvents.forEach((entryEvent: EntryEventConfig, entryEventIndex: number) => {
      updatedEventClassesAvailable.push(entryEvent.eventType === 'Rally' ? _.cloneDeep(rallyClasses) : _.cloneDeep(obedienceClasses));
      entryEvent.classesOffered.forEach((classOffered: string) => {
        const eventClassesAvailableIndex = _.findIndex(updatedEventClassesAvailable[updatedEventClassesAvailable.length - 1], val => val.name === classOffered);
        if (eventClassesAvailableIndex > -1) {
          updatedEventClassesAvailable[entryEventIndex][eventClassesAvailableIndex].isOffered = true;
        }
      });
    });
    setEventClassesAvailable(updatedEventClassesAvailable);
  }, [entryEvents, obedienceClasses, rallyClasses]);

  const handleChangeField = (e: any, index: number, fieldName: string) => {
    const updatedEntryEvents: EntryEventConfig[] = _.cloneDeep(entryEvents);
    if (fieldName === 'name') {
      updatedEntryEvents[index].name = e.target.value;
    }
    if (fieldName === 'eventNum') {
      updatedEntryEvents[index].eventNum = e.target.value;
    }
    if (fieldName === 'clubName') {
      updatedEntryEvents[index].clubName = e.target.value;
    }
    if (fieldName === 'location') {
      updatedEntryEvents[index].location = e.target.value;
    }
    if (fieldName === 'date') {
      updatedEntryEvents[index].date = e.target.value;
    }
    if (fieldName === 'eventType') {
      updatedEntryEvents[index].eventType = e.target.value;
      updatedEntryEvents[index].classesOffered = [];
    }
    if (fieldName === 'prices') {
      const prices = e.target.value.replace(/\$/g, '').split(',');
      const pricesTrimmed: string[] = [];
      prices.forEach((price: string) => {
        pricesTrimmed.push(price.trim());
      });
      updatedEntryEvents[index].prices = pricesTrimmed;
    }
    if (fieldName === 'juniorPrices') {
      const prices = e.target.value.replace(/\$/g, '').split(',');
      const pricesTrimmed: string[] = [];
      prices.forEach((price: string) => {
        pricesTrimmed.push(price.trim());
      });
      updatedEntryEvents[index].juniorPrices = pricesTrimmed;
    }
    dispatch(setEntryEvents(updatedEntryEvents));
    props.onChange();
  }

  const handleClickAddEntryEvent = () => {
    const updatedEntryEvents: EntryEventConfig[] = _.cloneDeep(entryEvents);
    updatedEntryEvents.push({ 
      name: '',
      eventNum: '',
      clubName: _.get(updatedEntryEvents[updatedEntryEvents.length - 1], 'clubName', ''),
      location: _.get(updatedEntryEvents[updatedEntryEvents.length - 1], 'location', ''),
      date: '',
      eventType: _.get(updatedEntryEvents[updatedEntryEvents.length - 1], 'eventType', ''),
      classesOffered: _.get(updatedEntryEvents[updatedEntryEvents.length - 1], 'classesOffered', []),
      prices: _.get(updatedEntryEvents[updatedEntryEvents.length - 1], 'prices', []),
      juniorPrices: _.get(updatedEntryEvents[updatedEntryEvents.length - 1], 'juniorPrices', []),
      specialPrices: _.get(updatedEntryEvents[updatedEntryEvents.length - 1], 'specialPrices', []),
    });
    const updatedEventClassesAvailable: {name: string, type: string, isOffered: boolean}[][] =[];
    updatedEntryEvents.forEach((entryEvent: EntryEventConfig, entryEventIndex: number) => {
      updatedEventClassesAvailable.push(entryEvent.eventType === 'Rally' ? _.cloneDeep(rallyClasses) : _.cloneDeep(obedienceClasses));
      entryEvent.classesOffered.forEach((classOffered: string) => {
        const eventClassesAvailableIndex = _.findIndex(updatedEventClassesAvailable[updatedEventClassesAvailable.length - 1], val => val.name === classOffered);
        if (eventClassesAvailableIndex > -1) {
          updatedEventClassesAvailable[entryEventIndex][eventClassesAvailableIndex].isOffered = true;
        }
      });
    });
    setEventClassesAvailable(updatedEventClassesAvailable);
    dispatch(setEntryEvents(updatedEntryEvents));
    props.onChange();
  }

  const handleClickDeleteEvent = (index: number) => {
    const updatedEntryEvents: EntryEventConfig[] = _.cloneDeep(entryEvents);
    updatedEntryEvents.splice(index, 1);
    dispatch(setEntryEvents(updatedEntryEvents));
    props.onChange();
  }

  const handleClickAddSpecialPrice = (entryEventIndex: number) => {
    const updatedEntryEvents: EntryEventConfig[] = _.cloneDeep(entryEvents);
    updatedEntryEvents[entryEventIndex].specialPrices.push({
      class: '',
      prices: []
    });
    dispatch(setEntryEvents(updatedEntryEvents));
    props.onChange();
  }

  const handleClickDeleteSpecialPrice = (entryEventIndex: number, specialPriceIndex: number) => {
    const updatedEntryEvents: EntryEventConfig[] = _.cloneDeep(entryEvents);
    updatedEntryEvents[entryEventIndex].specialPrices.splice(specialPriceIndex, 1);
    dispatch(setEntryEvents(updatedEntryEvents));
    props.onChange();
  }

  const handleChangeSpecialPriceFieldValue = (entryEventIndex: number, specialPriceIndex: number, fieldName: string, newValue: string) => {
    const updatedEntryEvents: EntryEventConfig[] = _.cloneDeep(entryEvents);

    if (fieldName === 'class') {
      updatedEntryEvents[entryEventIndex].specialPrices[specialPriceIndex].class = newValue;
    }
    if (fieldName === 'prices') {
      const prices = newValue.replace(/\$/g, '').split(',');
      const pricesTrimmed: string[] = [];
      prices.forEach((price: string) => {
        pricesTrimmed.push(price.trim());
      });
      updatedEntryEvents[entryEventIndex].specialPrices[specialPriceIndex].prices = pricesTrimmed;
    }
    dispatch(setEntryEvents(updatedEntryEvents));
    props.onChange();
  }

  const setClassOffered = (entryEventIndex: number, classIndex: number, checked: boolean) => {
    const name = eventClassesAvailable[entryEventIndex][classIndex].name;
    const updatedEntryEvents: EntryEventConfig[] = _.cloneDeep(entryEvents);
    if (checked) {
      updatedEntryEvents[entryEventIndex].classesOffered.push(name);
    } else {
      const classesOfferedIndex = _.findIndex(updatedEntryEvents[entryEventIndex].classesOffered, val => val === name);
      updatedEntryEvents[entryEventIndex].classesOffered.splice(classesOfferedIndex, 1);
      const specialPricesIndex = _.findIndex(updatedEntryEvents[entryEventIndex].specialPrices, val => val.class === name);
      if (specialPricesIndex > -1) {
        updatedEntryEvents[entryEventIndex].specialPrices.splice(specialPricesIndex, 1);
      }
    }
    dispatch(setEntryEvents(updatedEntryEvents));
    props.onChange();
  }

  return (
    <>
      <Grid key={'config-view-entry-events-header'} item md={12}>
        <h2>Entry Form</h2>
      </Grid>
      <Grid key={'config-view-entry-events-add-event'} item md={12}>
        <div className="config-view-entry-events-add">
          <Button 
            id="config-view-entry-events-add"
            color="primary" 
            variant="contained"
            className="config-view-button"
            style={{textTransform: 'none'}}
            onClick={handleClickAddEntryEvent}>
            Add Event
          </Button>
        </div>
      </Grid>
      { entryEvents.map((entryEvent: EntryEventConfig, entryEventIndex: number) => (
        <form key={'config-view-entry-events-' + entryEventIndex}>
          <Grid item md={12} p={2}>
            <div className="config-view-entry-events-field-container">
              <TextField 
                id={'config-view-entry-events-name-' + entryEventIndex}
                variant="outlined"
                size="small"
                className="config-view-entry-events-name"
                value={entryEvent.name}
                onChange={(e: any) => handleChangeField(e, entryEventIndex, 'name')}
                label="Name" />
                <HelpDialog subject={'entryEventName'} />
              <Tooltip title="Delete event">
                <IconButton 
                  id={'date-' + entryEventIndex + '-delete-button'}
                  key={entryEventIndex}
                  className="config-view-entry-events-delete-button"
                  color="primary" 
                  aria-label="delete event"
                  component="span"
                  onClick={() => handleClickDeleteEvent(entryEventIndex)}>
                  <DeleteIcon style={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Grid item md={12} p={2}>
            <TextField 
              id={'config-view-entry-events-event-num-' + entryEventIndex}
              variant="outlined"
              size="small"
              className="config-view-entry-events-event-num"
              value={entryEvent.eventNum}
              onChange={(e: any) => handleChangeField(e, entryEventIndex, 'eventNum')}
              label="AKC Event Number" />
          </Grid>
          <Grid item md={12} p={2}>
            <TextField 
              id={'config-view-entry-events-club-name-' + entryEventIndex}
              variant="outlined"
              size="small"
              className="config-view-entry-events-club-name"
              value={entryEvent.clubName}
              onChange={(e: any) => handleChangeField(e, entryEventIndex, 'clubName')}
              label="Club Name" />
          </Grid>
          <Grid item md={12} p={2}>
            <TextField 
              id={'config-view-entry-events-location-' + entryEventIndex}
              variant="outlined"
              size="small"
              className="config-view-entry-events-location"
              value={entryEvent.location}
              onChange={(e: any) => handleChangeField(e, entryEventIndex, 'location')}
              label="Location" />
          </Grid>
          <Grid item md={12} p={2}>
            <TextField 
              id={'config-view-entry-events-date-' + entryEventIndex}
              variant="outlined"
              size="small"
              className="config-view-entry-events-date"
              value={entryEvent.date}
              onChange={(e: any) => handleChangeField(e, entryEventIndex, 'date')}
              label="Event Date" />
          </Grid>
          <Grid item md={12} p={2}>
            <InputLabel id="config-view-entry-events-event-type-label">Event Type</InputLabel>
            <Select
              id="config-view-entry-events-event-type-select"
              labelId="config-view-entry-events-event-type-label"
              label="Event Type"
              style={{width: 300}}
              value={_.get(entryEvent, 'eventType', '')}
              onChange={(e: any) => handleChangeField(e, entryEventIndex, 'eventType')}>
              <MenuItem value="Obedience">Obedience</MenuItem>
              <MenuItem value="Rally">Rally</MenuItem>
            </Select>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            { eventClassesAvailable && eventClassesAvailable.length > 0 ?
              <Grid item md={3}>
                <h3 className="config-view-entry-events-class-header">Classes Offered</h3>
                { eventClassesAvailable[entryEventIndex].map((classOffered: any, classIndex: number) => 
                  <div className="config-view-entry-events-class" key={'config-view-entry-events-class-' + entryEventIndex + '-' + classIndex}>
                    <Checkbox 
                      checked={classOffered.isOffered}
                      id={'class-' + classIndex}
                      name={'class-' + classIndex}
                      onChange={(e: any) => setClassOffered(entryEventIndex, classIndex, e.target.checked)}
                      color="primary" />
                    <span>{classOffered.name}</span>
                  </div>
                )}
              </Grid>
              : null
            }
          </Grid>
          <Grid item md={12} p={2}>
            <div className="config-view-entry-events-field-container">
              <TextField 
                id={'config-view-entry-events-prices-' + entryEventIndex}
                variant="outlined"
                size="small"
                className="config-view-entry-events-prices"
                value={entryEvent.prices}
                onChange={(e: any) => handleChangeField(e, entryEventIndex, 'prices')}
                label="Prices" />
              <HelpDialog subject={'entryEventPrices'} />
            </div>
          </Grid>
          <Grid item md={12} p={2}>
            <div className="config-view-entry-events-field-container">
              <TextField 
                id={'config-view-entry-events-junior-prices-' + entryEventIndex}
                variant="outlined"
                size="small"
                className="config-view-entry-events-junior-prices"
                value={entryEvent.juniorPrices}
                onChange={(e: any) => handleChangeField(e, entryEventIndex, 'juniorPrices')}
                label="Junior Prices" />
              <HelpDialog subject={'entryEventPrices'} />
            </div>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <>
              <Grid item md={12} p={2}>
                <h3>Classes with Special prices</h3>
              </Grid>
              <Grid item md={12} p={2}>
                <Button 
                  id="config-view-entry-events-special-prices-add"
                  color="primary" 
                  variant="contained"
                  className="config-view-button"
                  style={{textTransform: 'none'}}
                  onClick={() => handleClickAddSpecialPrice(entryEventIndex)}>
                  Add Special Price
                </Button>
              </Grid>
              { entryEvent.specialPrices.map((specialPrice: any, specialPricesIndex: number) => 
                <React.Fragment key={`config-view-entry-events-special-prices-${entryEventIndex}-${specialPricesIndex}`}>
                  <Grid item md={3} p={2}>
                    <InputLabel id="config-view-entry-events-special-prices-label">Class</InputLabel>
                      <Select
                        id="config-view-entry-events-special-prices-select"
                        labelId="config-view-entry-events-special-prices-label"
                        label="Class"
                        style={{width: 200}}
                        value={specialPrice.class}
                        onChange={(e: any) => handleChangeSpecialPriceFieldValue(entryEventIndex, specialPricesIndex, 'class', e.target.value.toString())}>
                        { entryEvent.classesOffered.map((val: string, classesOfferedIndex: number) => (
                          <MenuItem key={'config-view-entry-events-special-prices-select-' + classesOfferedIndex} value={val}>{val}</MenuItem>
                        ))}
                      </Select>
                  </Grid>
                  <Grid item md={3} p={2}>
                    <div className="config-view-entry-events-field-container">
                      <TextField 
                        id={'config-view-entry-events-special-prices-price-' + entryEventIndex}
                        variant="outlined"
                        size="small"
                        className="config-view-entry-events-special-prices-price"
                        value={entryEvent.specialPrices[specialPricesIndex].prices}
                        onChange={(e: any) => handleChangeSpecialPriceFieldValue(entryEventIndex, specialPricesIndex, 'prices', e.target.value.toString())}
                        label="Special Prices" />
                      <HelpDialog subject={'entryEventPrices'} />
                    </div>
                  </Grid>
                  <Grid item md={2} p={2}>
                    <Button 
                      id="config-view-entry-events-special-prices-delete"
                      color="secondary" 
                      variant="contained"
                      className="config-view-button"
                      style={{textTransform: 'none'}}
                      onClick={() => handleClickDeleteSpecialPrice(entryEventIndex, specialPricesIndex)}>
                      Delete
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </>
          </Grid>
        </form>
      ))}
    </>
  )
}
