import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Checkbox, FormControl, Grid,  InputLabel,  Link, MenuItem,  Select,  TextField } from '@mui/material';
import { Error as ErrorIcon, Warning as WarningIcon } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { validateOwnerAddressFields, validateDogFields, validateDogJrHandlerFields, validateDogBreedAkcNumFields } from '../../../utils/validateFormFields';
import { setRegistrant } from '../../../redux/reducers/registrantSlice';
import HelpDialog from '../../atoms/helpDialog/helpDialog';
import { UserRecord } from '../../../models/UserRecord';
import { UserRecordDog } from '../../../models/UserRecordDog';
import axios from 'axios';
import _ from 'lodash';
import './userProfileView.css';
import { v4 as uuidv4 } from 'uuid';

const formValidationRecord:any = {
  first: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  last: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  street: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  city: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  state: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  zip: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  email: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  phone: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  classesEntered: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  breed: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  variety: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  sex: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  jrHandlerName: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  jrHandlerNumber: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  callName: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  akcName: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  akcRegistrationNum: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  dateOfBirth: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  countryOfBirth: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  breeder: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  sire: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  dam: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  owners: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  },
  handler: {
    isWarning: false,
    isError: false,
    isDirty: false,
    warningMsg: '',
    errorMsg: ''
  }
}

export default function UserProfileView() {

  const [ isLoading, setIsLoading ] = useState(true);
  const [ isServerError, setIsServerError ] = useState(false);
  const { email, isAuth0Authenticated, jwtToken, firstName, lastName } = useAppSelector((state: any) => state.registrant);
  const { isEventAdmin } = useAppSelector((state: any) => state.userProfile);
  const { user, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const [ stateAbbreviations, setStateAbbreviations ] = useState<string[]>([ 'NC' ]);
  const [ akcRegistrationPrefix, setAkcRegistrationPrefix ] = useState<any[]>([]);
  const [ breeds, setBreeds ] = useState<string[]>([ 'Shetland Sheepdog']);
  const [ breedGroup, setBreedGroup ] = useState<any[]>([]);
  const [ formValidation, setFormValidation ] = useState<any>(formValidationRecord);
  const [ isDirty, setIsDirty ] = useState<boolean>(false);
  const [ numErrors, setNumErrors ] = useState<number>(0);
  const [ selectedDog, setSelectedDog ] = useState<any>(null);
  const [ msg, setMsg ] = useState<string>('');
  const [ userRecord, setUserRecord ] = useState<UserRecord>();

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    async function fetchData() {
      try {
        setIsServerError(false);
        const stateAbbreviationsRsp = await axios.get(`/api/constants/state-abbreviations`);
        const updatedStateAbbreviations = _.get(stateAbbreviationsRsp, 'data.states', []);
        updatedStateAbbreviations.splice(0, 0, '');
        const akcregistrationPrefixRsp = await axios.get(`/api/constants/akc-registration-prefix`);
        const updatedAkcregistrationPrefix = _.get(akcregistrationPrefixRsp, 'data.groups', []);
        const akcBreedsRsp = await axios.get(`/api/constants/akc-breeds`);
        const breedsRsp: any = _.get(akcBreedsRsp, 'data.breeds', []);
        const updatedBreeds: string[] = [];
        const updatedBreedGroup: any[] = [];
        breedsRsp.forEach((breed: any) => {
          updatedBreeds.push(breed.name);
          updatedBreedGroup.push(breed);
        });
        setStateAbbreviations(updatedStateAbbreviations);
        setAkcRegistrationPrefix(updatedAkcregistrationPrefix);
        setBreeds(updatedBreeds);
        setBreedGroup(updatedBreedGroup);
        const userRsp = await axios.get(`/api/protected/user/${email}`, {
          headers: { 
            Authorization: `Bearer ${jwtToken}`,
          }
        });
        if (userRsp.data && !userRsp.data.error) {
          setUserRecord(userRsp.data);
          initFormValidationRecordForOwner(userRsp.data);
        } else {
          setIsServerError(true);
        }
        setIsLoading(false);
      } catch(err: any) {
        console.log('get user profile err=' + JSON.stringify(err, null, 2));
        setIsLoading(false);
        setIsServerError(true);
      }
    }
    if (jwtToken) {
      fetchData();
    }
  }, [email, getAccessTokenWithPopup, getAccessTokenSilently, isAuth0Authenticated, user, dispatch, jwtToken]);

  const handleChangeFieldValue = (fieldName: string, newValue: string) => {
    const updatedUserRecord: any = _.cloneDeep(userRecord);
    updatedUserRecord.ownerAddress[`${fieldName.replace('ownerAddress.', '')}`] = newValue;
    setUserRecord(updatedUserRecord);
    setFormValidation(fieldValidation(formValidation, fieldName, newValue, selectedDog));
    setIsDirty(true);
  }

  const handleChangeFieldValueDog = (fieldName: string, newValue: string) => {
    const updatedUserRecord: any = _.cloneDeep(userRecord);

    const dogIndex = _.findIndex(updatedUserRecord.dogs, (val: any) => val.uuid === selectedDog.uuid);
    
    if (fieldName.indexOf('akcRegistrationNum') > -1) {
      newValue = newValue.toUpperCase();
    }
  
    updatedUserRecord.dogs[dogIndex][`${fieldName}`] = newValue;
    setUserRecord(updatedUserRecord);
    const updatedSelectedDog = _.cloneDeep(selectedDog);
    updatedSelectedDog[`${fieldName}`] = newValue;
    setSelectedDog(updatedSelectedDog);
    setFormValidation(fieldValidation(formValidation, fieldName, newValue, updatedSelectedDog));
    setIsDirty(true);
  }

  const fieldValidation = (formValidation: any, fieldName: string, value: string, dog: UserRecordDog | null) => {
    let updatedFormValidation = _.cloneDeep(formValidation);

    if (fieldName.startsWith('ownerAddress')) {
      updatedFormValidation = validateOwnerAddressFields(updatedFormValidation, fieldName.replace('ownerAddress.', ''), value);
    } else if (dog && ['jrHandlerName', 'jrHandlerNumber'].includes(fieldName)) {
      updatedFormValidation = validateDogJrHandlerFields(updatedFormValidation, fieldName, value, dog.jrHandlerNumber, dog.jrHandlerName)
    } else if (dog && ['breed', 'akcRegistrationNum'].includes(fieldName)) {
      updatedFormValidation = validateDogBreedAkcNumFields(updatedFormValidation, fieldName, value, dog.akcRegistrationNum, dog.breed, breedGroup, akcRegistrationPrefix)
    } else {
      updatedFormValidation = validateDogFields(updatedFormValidation, fieldName, value);
    }
    let errors = 0;
    Object.keys(updatedFormValidation).forEach((key: string) => {
      errors += updatedFormValidation[`${key}`].isError ? 1 : 0;
    });
    setNumErrors(errors);
    return updatedFormValidation;
  }

  const handleClickSaveProfile = async () => {
    /* istanbul ignore next */
    if (!userRecord) {
      return;
    }
    setRegistrant({
      firstName: userRecord.ownerAddress.first,
      lastName: userRecord.ownerAddress.last
    });
    const dataRsp: any = await axios.post(`/api/protected/user/${userRecord._id}`, userRecord, { headers: { Authorization: `Bearer ${jwtToken}` }, params: { email }});
    if (dataRsp.data.ok) {
      setMsg(`Save completed successfully`)
      setIsDirty(false);
    } else {
      setMsg(`Save failed. Please retry.`)
    }
  }

  const initFormValidationRecordForOwner = (user: UserRecord) => {
    const ownerFields = Object.keys(_.get(user, 'ownerAddress', {}));
    const newFormValidationRecord = _.cloneDeep(formValidationRecord);
    ownerFields.forEach((key: string) => {
      const updatedFormValidationRecord: any = fieldValidation(formValidation, `ownerAddress.${key}`, _.get(user, `ownerAddress.${key}`, ''), null);
      newFormValidationRecord[`${key}`] = updatedFormValidationRecord[`${key}`];
    });
    setFormValidation(newFormValidationRecord);
  }

  const initFormValidationRecordForDog = (dog: any) => {
    const dogFields = Object.keys(dog);
    const validationFields = Object.keys(formValidationRecord);
    const newFormValidationRecord = _.cloneDeep(formValidationRecord);
    dogFields.forEach((key: string) => {
      if (validationFields.includes(key)) {
        const updatedFormValidationRecord: any = fieldValidation(formValidation, key, dog[`${key}`], dog);
        newFormValidationRecord[`${key}`] = updatedFormValidationRecord[`${key}`];
      } 
    });
    setFormValidation(newFormValidationRecord);
  }
  
  const handleEditDog = (index: number) => {
    /* istanbul ignore next */
    if (!userRecord) {
      return;
    }
    setSelectedDog(userRecord.dogs[index]);
    initFormValidationRecordForDog(userRecord.dogs[index]);    
  }

  const handleAddDog = () => {
    /* istanbul ignore next */
    if (!userRecord) {
      return;
    }
    const defaultBreed = userRecord.dogs.length > 0 ? userRecord.dogs[userRecord.dogs.length -1].breed : 'All American';
    const defaultSex = userRecord.dogs.length > 0 ? userRecord.dogs[userRecord.dogs.length -1].sex : 'Bitch';
    const updatedUserRecord = _.clone(userRecord);
    const newDog: any = {
      uuid: uuidv4(),
      breed: defaultBreed,
      variety: '',
      sex: defaultSex,
      jrHandlerName: '',
      jrHandlerNumber: '',
      callName: '',
      akcName: '',
      akcRegistrationNum: '',
      dateOfBirth: '',
      countryOfBirth: 'USA',
      breeder: '',
      sire: '',
      dam: '',
      owners: `${_.get(userRecord, 'ownerAddress.first', '')} ${_.get(userRecord, 'ownerAddress.last', '')}`.trim(),
      handler: ''
    }
    updatedUserRecord.dogs.push(newDog);
    setUserRecord(updatedUserRecord);
    initFormValidationRecordForDog(newDog);    
    setSelectedDog(updatedUserRecord.dogs[updatedUserRecord.dogs.length - 1]);
  }

  const handleDeleteDog = (index: number) => {
    /* istanbul ignore next */
    if (!userRecord) {
      return;
    }
    const updatedUserRecord = _.clone(userRecord);
    if (selectedDog && selectedDog.uuid === updatedUserRecord.dogs[index].uuid) {
      setSelectedDog(null);
    }
    updatedUserRecord?.dogs.splice(index, 1);
    setUserRecord(updatedUserRecord);
    setIsDirty(true);
  }

  const getFriendlyReferrerName = (referrer: string) => {
    const ref = referrer.replace('https://localhost:3000/', '');
    /* istanbul ignore next */
    return ref === '' ? 'Home' : ref.charAt(0).toUpperCase() + ref.slice(1);
  }

  return (
    <>
      { isLoading || isServerError ?
        <>
        { isLoading ?
          <h1>Loading</h1>
          :
          <h1>Error retrieving data, please refresh the page</h1>
        }
        </>
      :
      <>
        <div>
          <Link href={document.referrer} style={{ textDecoration: 'none' }}>
            Back to { getFriendlyReferrerName(document.referrer) }
          </Link>
        </div>
        <h2>Owner information</h2>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item md={4} xs={12} p={2}>
            <FormControl fullWidth>
              <div>
                <TextField 
                  id={`user-profile-view-email`}
                  variant="outlined"
                  size="small"
                  disabled={true}
                  style={{ width: '400px'}}
                  value={_.get(userRecord, 'ownerAddress.email', '')}
                  label='Email' />
                <HelpDialog subject={'userProfileViewEmail'} />
              </div>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item md={4} xs={12} p={2}>
            <FormControl fullWidth>
              <TextField 
                id={`user-profile-view-first-name`}
                variant="outlined"
                size="small"
                required
                value={_.get(userRecord, 'ownerAddress.first', '')}
                onChange={(e: any) => handleChangeFieldValue('ownerAddress.first', e.target.value.toString())}
                label='First Name' />
              { formValidation.first.isError ?
                <span id="user-profile-view-error-first-name"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.first.errorMsg }</span>
                : 
                <>
                { formValidation.first.isWarning ?
                  <span id="user-profile-view-warning-first-name"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.first.warningMsg }</span>
                  : null
                }
                </>
              }
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} p={2}>
            <FormControl fullWidth>
              <TextField 
                id={`user-profile-view-last-name`}
                variant="outlined"
                size="small"
                required
                value={_.get(userRecord, 'ownerAddress.last', '')}
                onChange={(e: any) => handleChangeFieldValue('ownerAddress.last', e.target.value.toString())}
                label='Last Name' />
             { formValidation.last.isError ?
                <span id="user-profile-view-error-last-name"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.last.errorMsg }</span>
                : 
                <>
                { formValidation.last.isWarning ?
                  <span id="user-profile-view-warning-last-name"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.last.warningMsg }</span>
                  : null
                }
                </>
              }
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item md={4} xs={12} p={2}>
            <FormControl fullWidth>
              <TextField 
                id={`user-profile-view-street`}
                variant="outlined"
                size="small"
                required
                value={_.get(userRecord, 'ownerAddress.street', '')}
                onChange={(e: any) => handleChangeFieldValue('ownerAddress.street', e.target.value.toString())}
                label='Street' />
             { formValidation.street.isError ?
                <span id="user-profile-view-error-street"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.street.errorMsg }</span>
                : 
                <>
                { formValidation.street.isWarning ?
                  <span id="user-profile-view-warning-street"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.street.warningMsg }</span>
                  : null
                }
                </>
              }
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12} p={2}>
            <FormControl fullWidth>
              <TextField 
                id={`user-profile-view-city`}
                variant="outlined"
                size="small"
                required
                value={_.get(userRecord, 'ownerAddress.city', '')}
                onChange={(e: any) => handleChangeFieldValue('ownerAddress.city', e.target.value.toString())}
                label='City' />
             { formValidation.city.isError ?
                <span id="user-profile-view-error-city"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.city.errorMsg }</span>
                : 
                <>
                { formValidation.city.isWarning ?
                  <span id="user-profile-view-warning-city"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.city.warningMsg }</span>
                  : null
                }
                </>
              }
            </FormControl>
          </Grid>
          <Grid item md={2} xs={6} p={2}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                disableClearable
                id="user-profile-view-state-select"
                options={stateAbbreviations}
                value={_.get(userRecord, 'ownerAddress.state', '')}
                onChange={(e: any, newValue: any) => handleChangeFieldValue('ownerAddress.state', newValue.toString())}
                renderInput={(params) => <TextField {...params} label="State" />} />
              { formValidation.state.isError ?
                <span id="user-profile-view-error-state"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.state.errorMsg }</span>
                : null
              }
            </FormControl>
          </Grid>
          <Grid item md={3} xs={6} p={2}>
            <FormControl fullWidth>
              <TextField 
                id={`user-profile-view-zip`}
                variant="outlined"
                size="small"
                required
                error = { formValidation.zip.isError }
                onChange={(e: any) => handleChangeFieldValue('ownerAddress.zip', e.target.value.toString())}
                value={_.get(userRecord, 'ownerAddress.zip', '')}
                label='Zip code (##### or #####-####)' />
              { formValidation.zip.isError ?
                <span id="user-profile-view-error-zip"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.zip.errorMsg }</span>
                : 
                <>
                { formValidation.zip.isWarning ?
                  <span id="user-profile-view-warning-zip"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.zip.warningMsg }</span>
                  : null
                }
                </>
              }
            </FormControl>
          </Grid>
          <Grid item md={3} xs={6} p={2}>
            <FormControl fullWidth>
              <TextField 
                id={`user-profile-view-phone`}
                variant="outlined"
                size="small"
                required
                value={ _.get(userRecord, 'ownerAddress.phone', '') }
                error={ formValidation.phone.isError }
                onChange={(e: any) => handleChangeFieldValue('ownerAddress.phone', e.target.value.toString())}
                label='Phone (###-###-####)' />
              { formValidation.phone.isError ?
                <span id="user-profile-view-error-phone"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.phone.errorMsg }</span>
                : 
                <>
                { formValidation.phone.isWarning ?
                  <span id="user-profile-view-warning-phone"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.phone.warningMsg }</span>
                  : null
                }
                </>
              }
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item md={3} xs={12} p={1}>
            <Checkbox 
              checked={isEventAdmin}
              disabled={true}
              color="primary" />
            <span id="user-profile-view-event-admin">Event Administrator</span>
            <HelpDialog subject={'userProfileViewEventAdmin'} />
          </Grid>
        </Grid>
        { isEventAdmin ?
          <>
            <h2>
              Event information
              <HelpDialog subject={'userProfileViewEventManagement'} />
            </h2>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              { _.get(userRecord, 'events', []).length === 0 ?
                <h3>No Events defined</h3>
                :
                <>
                  { _.get(userRecord, 'events', []).map((event: any, eventsIndex: number) => (
                    <React.Fragment key={`user-profile-view-event-${eventsIndex}`}>
                      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item md={4} xs={12} p={1}>
                          <TextField 
                            id={`user-profile-view-event-${eventsIndex}`}
                            variant="outlined"
                            style={{ width: '100%'}}
                            disabled={true}
                            value={event}
                            label='Event Name' />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              }
            </Grid>
          </>
          : null
        }
        <h2>Dog information</h2>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item md={6} xs={6} p={1}>
            <Button 
              id="user-profile-view-add-dog"
              color="primary" 
              variant="contained"
              className="config-view-button"
              style={{textTransform: 'none'}}
              onClick={() => handleAddDog()}>
              Add Dog
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          { _.get(userRecord, 'dogs', []).length === 0 ?
            <h3>No Dogs defined</h3>
            :
            <>
              { _.get(userRecord, 'dogs', []).map((dog: any, dogsIndex: number) => (
                <React.Fragment key={`user-profile-view-dog-${dogsIndex}`}>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item md={2} xs={12} p={1}>
                      <TextField 
                        id={`user-profile-view-call-name-${dogsIndex}`}
                        className=""
                        variant="outlined"
                        disabled={true}
                        value={dog.callName}
                        label='Call Name' />
                    </Grid>
                    <Grid item md={2} xs={6} p={1}>
                      <Button 
                        id={`user-profile-view-edit-dog-${dogsIndex}`}
                        color="primary" 
                        variant="contained"
                        className="config-view-button user-profile-view-margin-right-10"
                        disabled={ dog.uuid === selectedDog?.uuid }
                        style={{textTransform: 'none'}}
                        onClick={() => handleEditDog(dogsIndex)}>
                        Edit Dog
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={6} p={1}>
                      <Button 
                        id={`user-profile-view-delete-dog-${dogsIndex}`}
                        color="primary" 
                        variant="contained"
                        className="config-view-button"
                        style={{textTransform: 'none'}}
                        onClick={() => handleDeleteDog(dogsIndex)}>
                        Delete Dog
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </>
          }
        
          { selectedDog ?
            <>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item md={4} xs={12} p={1}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="user-profile-view-breed-select"
                      options={breeds}
                      value={_.get(selectedDog, 'breed', 'All American')}
                      onChange={(e: any, newValue: any) => handleChangeFieldValueDog('breed', newValue)}
                      renderInput={(params) => <TextField {...params} label="Breed" />} />
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id='user-profile-view-variety'
                      variant="outlined"
                      value={_.get(selectedDog, 'variety', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('variety', e.target.value.toString())}
                      label='Variety (optional)' />
                  </FormControl>
                </Grid>
                <Grid item md={2} p={1}>
                  <FormControl fullWidth>
                    <InputLabel id="user-profile-view-sex-label">Sex</InputLabel>
                    <Select
                      id="user-profile-view-sex-select"
                      labelId="user-profile-view-sex-label"
                      label="Sex"
                      style={{width: 200}}
                      value={_.get(selectedDog, 'sex', 'Bitch')}
                      onChange={(e: any) => handleChangeFieldValueDog('sex', e.target.value.toString())}>
                      <MenuItem value="Bitch">Bitch</MenuItem>
                      <MenuItem value="Dog">Dog</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-jr-handler-name'}
                      variant="outlined"
                      value={_.get(selectedDog, 'jrHandlerName', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('jrHandlerName', e.target.value.toString())}
                      label='Name of Junior Handler' />
                    { formValidation.jrHandlerName.isWarning ?
                      <span id="user-profile-view-warning-jr-handler-name"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.jrHandlerName.warningMsg }</span>
                      : null
                    }
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-jr-handler-number'}
                      variant="outlined"
                      value={_.get(selectedDog, 'jrHandlerNumber', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('jrHandlerNumber', e.target.value.toString())}
                      label='Junior Handler Number' />
                    { formValidation.jrHandlerNumber.isWarning ?
                      <span id="user-profile-view-warning-jr-handler-number"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.jrHandlerNumber.warningMsg }</span>
                      : null
                    }
                  </FormControl>
                </Grid>
                <Grid item md={8} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-akc-name'}
                      variant="outlined"
                      error={ formValidation.akcName.isError }
                      value={_.get(selectedDog, 'akcName', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('akcName', e.target.value.toString())}
                      label='Full Name of Dog (including titles)' />
                      { formValidation.akcName.isError ?
                        <span id="user-profile-view-error-akc-name"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.akcName.errorMsg }</span>
                        : 
                        <>
                        { formValidation.akcName.isWarning ?
                          <span id="user-profile-view-warning-akc-name"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.akcName.warningMsg }</span>
                          : null
                        }
                        </>
                      }
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-call-name'}
                      variant="outlined"
                      error={ formValidation.callName.isError }
                      value={_.get(selectedDog, 'callName', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('callName', e.target.value.toString())}
                      label='Call Name' />
                    { formValidation.callName.isError ?
                      <span id="user-profile-view-error-call-name"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.callName.errorMsg }</span>
                      : 
                      null
                    }
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-akc-registration-num'}
                      variant="outlined"
                      error={ formValidation.akcRegistrationNum.isError }
                      value={_.get(selectedDog, 'akcRegistrationNum', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('akcRegistrationNum', e.target.value.toString())}
                      label='AKC Registration Number' />
                    { formValidation.akcRegistrationNum.isError ?
                      <span id="user-profile-view-error-akc-registration-num"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.akcRegistrationNum.errorMsg }</span>
                      : 
                      <>
                        { formValidation.akcRegistrationNum.isWarning ?
                          <span id="user-profile-view-warning-akc-registration-num"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.akcRegistrationNum.warningMsg }</span>
                          : null
                        }
                      </>
                    }
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={6} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-date-of-birth'}
                      variant="outlined"
                      value={_.get(selectedDog, 'dateOfBirth', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('dateOfBirth', e.target.value.toString())}
                      label='Date of Birth (MM/DD/YYYY)' />
                    { formValidation.dateOfBirth.isError ?
                      <span id="user-profile-view-error-date-of-birth"><WarningIcon className="user-profile-view-error-icon" />{ formValidation.dateOfBirth.errorMsg }</span>
                      : 
                      <>
                        { formValidation.dateOfBirth.isWarning ?
                        <span id="user-profile-view-warning-date-of-birth"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.dateOfBirth.warningMsg }</span>
                        : null
                        }
                      </>
                    }
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={6} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-country-of-birth'}
                      variant="outlined"
                      value={_.get(selectedDog, 'countryOfBirth', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('countryOfBirth', e.target.value.toString())}
                      label='Place of Birth (list country)' />
                    { formValidation.countryOfBirth.isWarning ?
                      <span id="user-profile-view-warning-country-of-birth"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.countryOfBirth.warningMsg }</span>
                      : null
                    }
                  </FormControl>
                </Grid>
                <Grid item md={8} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-breeder'}
                      variant="outlined"
                      value={_.get(selectedDog, 'breeder', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('breeder', e.target.value.toString())}
                      label='Breeder' />
                    { formValidation.breeder.isWarning ?
                      <span id="user-profile-view-warning-breeder"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.breeder.warningMsg }</span>
                      : null
                    }
                  </FormControl>
                </Grid>
                <Grid item md={8} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-sire'}
                      variant="outlined"
                      value={_.get(selectedDog, 'sire', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('sire', e.target.value.toString())}
                      label='Sire' />
                    { formValidation.sire.isWarning ?
                      <span id="user-profile-view-warning-sire"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.sire.warningMsg }</span>
                      : null
                    }
                  </FormControl>
                </Grid>
                <Grid item md={8} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-dam'}
                      variant="outlined"
                      value={_.get(selectedDog, 'dam', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('dam', e.target.value.toString())}
                      label='Dam' />
                    { formValidation.dam.isWarning ?
                      <span id="user-profile-view-warning-dam"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.dam.warningMsg }</span>
                      : null
                    }
                  </FormControl>
                </Grid>
                <Grid item md={8} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-owners'}
                      variant="outlined"
                      error={ formValidation.owners.isError }
                      value={_.get(selectedDog, 'owners', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('owners', e.target.value.toString())}
                      label='Actual Owner' />
                    { formValidation.owners.isError ?
                      <span id="user-profile-view-error-owners"><ErrorIcon className="user-profile-view-error-icon" />{ formValidation.owners.errorMsg }</span>
                      : 
                      <>
                        { formValidation.owners.isWarning ?
                          <span id="user-profile-view-warning-owners"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.owners.warningMsg }</span>
                          : null
                        }
                      </>
                    }
                  </FormControl>
                </Grid>
                <Grid item md={8} xs={12} p={1}>
                  <FormControl fullWidth>
                    <TextField 
                      id={'user-profile-view-handler'}
                      variant="outlined"
                      error={ formValidation.handler.isError }
                      value={_.get(selectedDog, 'handler', '')}
                      onChange={(e: any) => handleChangeFieldValueDog('handler', e.target.value.toString())}
                      label='Handler' />
                    { formValidation.handler.isWarning ?
                      <span id="user-profile-view-warning-handler"><WarningIcon className="user-profile-view-warning-icon" />{ formValidation.handler.warningMsg }</span>
                      : null
                    }
                  </FormControl>
                </Grid>
              </Grid>
            </>
            : null
          }
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Button 
            id="user-profile-view-save"
            color="primary" 
            variant="contained"
            className="config-view-button user-profile-view-margin-right-10"
            style={{textTransform: 'none'}}
            disabled={!isDirty || numErrors > 0}
            onClick={() => handleClickSaveProfile()}>
            Save Profile
          </Button>
          { msg !== '' ? 
            <span id="user-profile-view-save-msg">{msg}</span>
            : null
          }
        </Grid>
      </>
      }
    </>
  )
}
