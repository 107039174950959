import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { setFirstName, setLastName, setRegistrant } from '../../../redux/reducers/registrantSlice';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import axios from 'axios';
import './registrantInfo.css';

interface IRegistrantInfo {
  eventId: string
}

export default function RegistrantInfo(props: IRegistrantInfo) {

  const [ currentEmail, setCurrentEmail ] = useState('');

  const { firstName, lastName, email } = useAppSelector((state: any) => state.registrant);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setCurrentEmail(email);
  }, [ email ]);
  
  const handleChangeRegistrantEmail = (event: any) => {
    event.preventDefault();
    const newEmail = event.target.value;
    setCurrentEmail(newEmail);

    if (newEmail === '') {
      dispatch(setRegistrant({
        firstName: '',
        lastName: '',
        email: '',
        isAdmin: false,
        isAuth: false,
        allowCookies: false
      }))
    }
    if ((/^[A-Za-z0-9-_.]{1,64}@[A-Za-z0-9-_.]{1,256}(com|net|edu|gov|org)$/).test(newEmail)) {
      if (props.eventId) {
        axios.get(`/api/isAdmin${props.eventId}`, { params: { email: newEmail }})
        .then((res: any) => {
          dispatch(setRegistrant({
            email: newEmail,
            isAdmin: res.data.isAdmin,
            isAuth: false
          }));
        })
        .catch((err: any) => {
          console.log('get isAdmin err=' + JSON.stringify(err, null, 2));
        });  
      }  
    }
  }

  const handleChangeRegistrantFirstName = (event: any) => {
    event.preventDefault();
    const newFirstName = event.target.value.trim();
    if (newFirstName !== '') {
      dispatch(setFirstName(newFirstName));
    } else {
      dispatch(setRegistrant({
        firstName: '',
        isAdmin: false,
        isAuth: false,
        allowCookies: false
      }));
    }
  }

  const handleChangeRegistrantLastName = (event: any) => {
    event.preventDefault();
    const newLastName = event.target.value.trim();
    if (newLastName !== '') {
      dispatch(setLastName(newLastName));
    } else {
      dispatch(setRegistrant({
        lastName: '',
        isAdmin: false,
        isAuth: false,
        allowCookies: false
      }));
    }
  }

  return (
    <div className="registrant-info noprint">
      <Grid container justifyContent="center" alignItems="center" alignContent="center">
        <Grid item md={3} xs={12} p={1}>
          <TextField fullWidth
            id="registrant-first-name"
            className="registrant-info-input"
            value={firstName}
            onChange={(e: any) => handleChangeRegistrantFirstName(e)}
            label="First Name" />
        </Grid>
        <Grid item md={3} xs={12} p={1}>
            <TextField fullWidth
            id="registrant-last-name"
            className="registrant-info-input"
            value={lastName}
            onChange={(e: any) => handleChangeRegistrantLastName(e)}
            label="Last Name" />
        </Grid>
        <Grid item md={6} xs={12} p={1}>
          <TextField fullWidth
            id="registrant-email"
            className="registrant-info-input"
            value={currentEmail}
            onChange={(e: any) => handleChangeRegistrantEmail(e)}
            label="Your Email" />
        </Grid>
      </Grid>
    </div>
  )
}
