import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import './deleteConfirmationDialog.css';

interface IDeleteConfirmationDialog {
  targetType: string,
  open: boolean,
  name: string,
  closeDialog: (action: string, name: string) => void
}

class DeleteConfirmationDialog extends Component<IDeleteConfirmationDialog> {

  constructor(props: IDeleteConfirmationDialog) {
    super(props);
    this.state = {
      open: false,
      targetType: '',
      name: ''
    }
  }

  handleCloseDialog(e: any, action: string) {
    e.preventDefault();
    this.props.closeDialog(action, this.props.name);
  }

  render() {
    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={(e) => this.handleCloseDialog(e, 'dismiss')}
          aria-labelledby="delete-confirmation-dialog-title"
          aria-describedby="delete-confirmation-dialog-description"
        >
          <DialogTitle id="delete-confirmation-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-confirmation-dialog-description">
              <span>Are you sure you want to delete {this.props.name}?</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              id="deleteConfirmationDialog-confirm"
              onClick={(e) => this.handleCloseDialog(e, 'confirm')}
              color="primary">
              Yes
            </Button>
            <Button 
              id="deleteConfirmationDialog-dismiss"
              onClick={(e) => this.handleCloseDialog(e, 'dismiss')}
              color="primary">
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DeleteConfirmationDialog
