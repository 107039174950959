import React from 'react';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setDates } from '../../../redux/reducers/eventConfigSlice';
import './configViewDates.css';
import { DateConfig } from '../../../models/DateConfig';
import _ from 'lodash';

interface IConfigViewDates {
  onChange: () => void
}

export default function ConfigViewDates(props: IConfigViewDates) {

  const { dates } = useAppSelector((state: any) => state.eventConfig);

  const dispatch = useAppDispatch();

  const handleChangeDateField = (e: any, index: number)  => {
    const updatedDates = _.cloneDeep(dates);
    updatedDates[index].date = new Date(e).toISOString().split('T')[0] + 'T12:00:00.000Z';
    dispatch(setDates(updatedDates));
    props.onChange();
  }

  const handleClickAddDate = () => {
    const updatedDates = _.cloneDeep(dates);
    updatedDates.push({ date: new Date().toISOString(), status: 'Upcoming', comment: '' });
    dispatch(setDates(updatedDates));
    props.onChange();
  }

  const handleClickDeleteDate= (index: number) => {
    const updatedDates = _.cloneDeep(dates);
    updatedDates.splice(index, 1);
    dispatch(setDates(updatedDates));
    props.onChange();
  }

  return (
    <>
      <Grid key={'event-date-header'} item md={12}>
        <h2>Dates</h2>
      </Grid>
      <Grid key={'event-date-add'} item md={12}>
        <div className="config-view-date-add">
          <Button 
            id="configView-add-date"
            color="primary" 
            variant="contained"
            className="config-view-button"
            style={{textTransform: 'none'}}
            onClick={handleClickAddDate}>
            Add Date
          </Button>
        </div>
      </Grid>
      { dates.map((dateObj: DateConfig, index: number) => (
        <Grid key={'event-date-' + index} item md={12} p={2}>
          <div className="config-view-date">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={dateObj.date}
                onChange={(e) => handleChangeDateField(e, index)}
                renderInput={(params) => <TextField { ...params } />}
              />
            </LocalizationProvider>
            <Tooltip title="Delete date">
              <IconButton 
                id={'date-' + index + '-delete-button'}
                key={index}
                className="date-delete-button"
                color="primary" 
                aria-label="delete date"
                component="span"
                onClick={() => handleClickDeleteDate(index)}>
                <DeleteIcon style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      ))}
    </>
  )
}
