import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { setDates } from '../../../redux/reducers/eventConfigSlice';
import { setData } from '../../../redux/reducers/eventDataSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import './classView.css';
import { DateConfig } from '../../../models/DateConfig';
import { ReservationInfo } from '../../../models/ReservationInfo';
import { DataInfo } from '../../../models/DataInfo';
import _ from 'lodash';

interface IClassView {
  onClassStatusChange: (newValue: any) => void
  onStudentAttendanceStatusChange: (reservation: ReservationInfo) => void
}

export default function ClassView(props: IClassView) {

  const { isAdmin, isAuth } = useAppSelector((state: any) => state.registrant);
  const { title, dates } = useAppSelector((state: any) => state.eventConfig);
  const { data } = useAppSelector((state: any) => state.eventData);

  const dispatch = useAppDispatch();

  const [ datesPresent, setDatesPresent ] = useState<boolean[][]>([]);

  useEffect(() => {
    const updatedDatesPresent: boolean[][] = [];
    const currentData = _.cloneDeep(data);
    if (currentData.length > 0) {
      currentData.forEach((dataRecord: DataInfo, dataIndex: number) => {
        updatedDatesPresent.push([]);
        const attendance: string[] = _.get(dataRecord, 'reservations[0].attendance', []);
        dates.forEach((dateObj: DateConfig) => {
          const present: boolean = attendance.includes(dateObj.date) ? true : false;
          updatedDatesPresent[dataIndex].push(present);
        });
      });
      setDatesPresent(updatedDatesPresent);
    }
  }, [dates, data]);

  const formatDate = (val: string) => {
    if (!val) {
      return '';
    }
    const mm = val.substring(5, 7);
    const dd = val.substring(8, 10);
    return `${mm}/${dd}`
  }

  const handleClickStudentStatus = (dataIndex: number, dateIndex: number, dateString: string) => {
    const updatedData: DataInfo[] = _.cloneDeep(data);

    let isPresent = !datesPresent[dataIndex][dateIndex];

    const updatedDatesPresent = _.cloneDeep(datesPresent);
    updatedDatesPresent[dataIndex][dateIndex] = isPresent;
    setDatesPresent(updatedDatesPresent);
    // TODO: currently we assume there's only one dog per handler in class, thus we can assume reservation[0].
    // It would be nice to allow multiple. But that means the display loop can't just be data, and here we need 
    // to determine the proper reservation to change 
    if (isPresent) {
      updatedData[dataIndex].reservations[0].attendance.push(dateString);
    } else {
      const attendenceIndex = _.findIndex(updatedData[dataIndex].reservations[0].attendance, (val: string) => val === dateString);
      updatedData[dataIndex].reservations[0].attendance.splice(attendenceIndex, 1);
    }
    dispatch(setData(updatedData));
    props.onStudentAttendanceStatusChange({ 
      ...updatedData[dataIndex].reservations[0],
      registrantId: updatedData[dataIndex].registrant.registrantId,
      registrantFirstName: updatedData[dataIndex].registrant.firstName,
      registrantLastName: updatedData[dataIndex].registrant.lastName,
      registrantEmail: updatedData[dataIndex].registrant.email
    });
  }

  const getStudentStatusButtonLabel = (reservationIndex: number, dateIndex: number) => {
    if (
      datesPresent[reservationIndex] && 
      datesPresent[reservationIndex].length >= dateIndex
    ) {
      return datesPresent[reservationIndex][dateIndex] ? 'Mark Absent' : 'Mark Present';
    } 
    return 'Unknown'
  }

  const handleClickClassStatus = (dateIndex: number, status: string) => {
    const updatedDates: DateConfig[] = _.cloneDeep(dates);
    updatedDates[dateIndex].status = status;
    dispatch(setDates(updatedDates));
    props.onClassStatusChange(updatedDates);
  }

  const getNumberOfAttendees = (classDate: string) => {
    if (new Date().toISOString() < classDate) {
      return '';
    }
    let attendees = 0;
    let classSize = 0;

    data.forEach((dataRecord: DataInfo) => {
      dataRecord.reservations.forEach((reservation: ReservationInfo) => {
        classSize++;
        if (reservation.attendance && reservation.attendance.includes(classDate)) {
          attendees++;
        }
      });
    });
    return `${attendees} of ${classSize}`
  }

  const invokeEmailApp = () => {
    const emails: string[] = [];
    data.forEach((dataRecord: DataInfo) => {
      emails.push(dataRecord.registrant.email);
    });
    window.open(`mailto: ${emails.toString()}?subject=${title}`);
  }

  const getClassDateColor = (status: string) => {
    if (status === 'Held') {
      return 'green';
    }
    if (status === 'Cancelled') {
      return 'red';
    }
    return 'black';
  }

  return (
    <div className='class-view'>
      { isAdmin && isAuth ?
        <div>
          <Button
            id="class-view-email-class-button"
            color="primary" 
            variant="contained"
            style={{textTransform: 'none'}}
            onClick={(e) => invokeEmailApp() }>
            Email the Class
          </Button>
        </div>
        : null
      }
      <h1>Class Summary</h1>
      { dates.map((dateObj: DateConfig, dateIndex: number) =>
        <Grid container key={'class-' + dateIndex } className='class-view-date'>
          <Grid id={'class-view-date-' + dateIndex} item md={1} xs={12} style={{ color: getClassDateColor(dateObj.status)}}>{ formatDate(dateObj.date) }</Grid>
          <Grid id={'class-view-status-' + dateIndex} item md={1} xs={12} style={{ color: getClassDateColor(dateObj.status)}}>{ dateObj.status }</Grid>
          <Grid id={'class-view-attendance-' + dateIndex} item md={1} xs={12}>{ getNumberOfAttendees(dateObj.date) }</Grid>
          <Grid item md={1} xs={12}>
            <Button
              id={ 'class-view-held-class-button-' + dateIndex }
              color="primary" 
              variant="contained"
              style={{textTransform: 'none'}}
              disabled={!(isAdmin && isAuth) || dateObj.status === 'Held' }
              onClick={() => handleClickClassStatus(dateIndex, 'Held')}>
              Held
            </Button>
          </Grid>
          <Grid item md={1} xs={12}>
            <Button
              id={ 'class-view-cancelled-class-button-' + dateIndex }
              color="primary" 
              variant="contained"
              style={{textTransform: 'none'}}
              disabled={!(isAdmin && isAuth)  || dateObj.status === 'Cancelled' }
              onClick={() => handleClickClassStatus(dateIndex, 'Cancelled')}>
              Cancelled
            </Button>
          </Grid>
        </Grid>
      )}

      <h1>Attendance</h1>
      <Grid container spacing={2}>
        { data.map((dataRecord: DataInfo, dataIndex: number) =>
          <Grid container key={'class-view-container-' + dataIndex }>
            { dataRecord.registrant.firstName !== '' ?
            <>
              <Grid item md={2} xs={12} className='class-view-student-name'>{ dataRecord.registrant.firstName } { dataRecord.registrant.lastName }</Grid>
              { dates.map((dateObj: DateConfig, dateIndex: number) => 
                <Grid key={'student-' + dataIndex + '-' + dateIndex } item md={1} xs={12} className='class-view-date'>{ formatDate(dateObj.date) }
                  <Button
                    id={ 'class-view-present-absent-button-' + dateIndex }
                    color="primary" 
                    variant="contained"
                    style={{textTransform: 'none'}}
                    disabled={!(isAdmin && isAuth) || dates[dateIndex].status === 'Cancelled' }
                    onClick={() => handleClickStudentStatus(dataIndex, dateIndex, dateObj.date)}>
                    {getStudentStatusButtonLabel(dataIndex, dateIndex)}
                  </Button>
                </Grid>
              )}
            </>
            : null
            }
          </Grid>
        )}
      </Grid>
    </div>
  )
}
