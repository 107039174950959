import React, { Component } from 'react';
import { IPrintableSession } from '../../organisms/adminView/adminView';
import _ from 'lodash';
import { ReservationInfo } from '../../../models/ReservationInfo';
import './printView.css';

interface IPrintView {
  printableSessions: IPrintableSession[],
}

class PrintView extends Component<IPrintView> {

  getNonEmptyReservations(sortedReservations: ReservationInfo[], runIndex: number) {
    return _.filter(sortedReservations, val => val.dogName !== '' && val.runs[runIndex] > 0);
  }

  render() {
    return (
      <>
        { this.props.printableSessions.map((session: IPrintableSession) => (
          <div key={'session-' + session.sessionIndex + '-run-' + session.runIndex  + '-wrapper'} className="admin-view-session-wrapper">
            <h2>{session.sessionName}</h2>
            <h3>{session.runName}</h3>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="dog-name">Dog</th>
                    <th className="breed">Breed</th>
                    <th className="jump-ht">Jump Ht</th>
                    <th className="result">Run #1</th>
                    <th className="result">Run #2</th>
                  </tr>
                </thead>
                <tbody>
                  { this.getNonEmptyReservations(session.sortedReservations, session.runIndex).map((val: ReservationInfo, index: number) => (
                    <tr key={'session-' + session.sessionIndex + '-run-' + session.runIndex + '-slot-' + val.id + '-row'}>
                      <td className="owner-name">{val.registrantFirstName} {val.registrantLastName}</td>
                      <td className="dog-name">{val.dogName}</td>
                      <td className="breed">{val.breed}</td>
                      <td className="jump-ht">{val.jumpHt}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </>
    )
  }
}

export default PrintView
