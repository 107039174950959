import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import './entryFormPrint.css';
import { useAppSelector } from '../../../redux/hooks';
import { EntryEventConfig } from '../../../models/EntryEventConfig';
import { EntryFormEventInfo } from '../../../models/EntryFormEventInfo';
import { EntryFormRecordData } from '../../../models/EntryFormRecordData';
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';

export default function EntryFormPrint() {

  const { entries, agreeToAgreementDate } = useAppSelector((state: any) => state.entryForms);
  const { entryEvents } = useAppSelector((state: any) => state.eventConfig);
  const [ printable, setPrintable ] = useState(false);
  const [ agreementText, setAgreementText ] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const akcAgreementRsp: any[] = await axios.get(`/api/constants/akc-agreement`);
        setAgreementText(_.get(akcAgreementRsp, 'data.text', ''));
      } catch(err: any) {
        console.log('get constants akc-agreement err=' + JSON.stringify(err, null, 2));
      }
    }
    fetchData();
  }, []);

  const getEventDate = (eventEntry: EntryFormEventInfo) => {
    const configIndex = _.findIndex(entryEvents, (val: EntryEventConfig) => val.eventNum === eventEntry.eventNum);
    return entryEvents[configIndex].date;
  }

  const getClassesEntered = (eventEntry: EntryFormEventInfo) => {
    const retValue: string[] = [];
    eventEntry.classes.forEach((eventEntryClass: any) => {
      if (eventEntryClass.isEntered) {
        retValue.push(`${eventEntryClass.name}(${eventEntryClass.jumpHt}")`);
      }
    });
    return retValue.length > 0 ? retValue.toString() : 'No classes entered';
  }

  const hasClass = (entry: EntryFormRecordData, name: string) => {
    let result = false;
    entry.eventEntries.forEach(eventEntry => {
      const index = _.findIndex(eventEntry.classes, (val: any) => val.name.indexOf(name) > -1 && val.isEntered);
      if (index > -1) {
        result = true;
      }
    });
    return result;
  }

  const getTeamName = (entry: EntryFormRecordData, name: string) => {
    let result = '';
    entry.eventEntries.forEach(eventEntry => {
      const index = _.findIndex(eventEntry.classes, (val: any) => val.name.indexOf(name) > -1 && val.isEntered);
      if (index > -1) {
        result = _.get(eventEntry.classes[index], 'teamName', '');
      }
    });
    return result;
  }

  return (
    <Grid container>
      <Grid item md={12} xs={12} className='noprint'>
        <Button 
          id="entry-form-print-print-button"
          color="primary" 
          variant="contained"
          style={{textTransform: 'none'}} 
          onClick={(e: any) => {
            e.preventDefault();
            setPrintable(!printable);
            setTimeout(() => { window.print(); setPrintable(false); }, 100);
            }}>
          Print Entry Forms
        </Button>
      </Grid>
      { printable ?
      <>
        { entries.map((entry: EntryFormRecordData, entryIndex: number) => 
          <div key={`entry-form-print-form-${entryIndex}`} className='entry-form-print-form'>
            <h2>ENTRY FORM</h2>
            <div className="entry-form-print-header">
              <div>{ entryEvents[0].clubName }</div>
              <div>{ entryEvents[0].location }</div>
              { entry.eventEntries.map((eventEntry: EntryFormEventInfo, eventEntryIndex: number) =>
                <div key={`entry-form-print-form-entry-event-${eventEntryIndex}`}>{`${getEventDate(eventEntry)} - ${eventEntry.eventId} - ${getClassesEntered(eventEntry)}`}</div>
              )}
              <div>Entry Fee: ${ entry.entryFee.toFixed(2) }</div>
            </div>
            <div className="table">
              <div className="row">
                { hasClass(entry, 'Brace') ? 
                  <div className="cell entry-form-print-brace"><em>Brace Partner:</em> {getTeamName(entry, 'Brace')}</div>
                  :
                  <div className="cell entry-form-print-brace"><em>Brace Partner:</em> not entered</div>
                }
                { hasClass(entry, 'Team') ? 
                  <div className="cell entry-form-print-team"><em>Team Name:</em> {getTeamName(entry, 'Team')}</div>
                  :
                  <div className="cell entry-form-print-team"><em>Team Name:</em> not entered</div>
                }
              </div>
              <div className="row">
                <div className="cell entry-form-print-breed"><em>Breed:</em> {entry.breed}</div>
                <div className="cell entry-form-print-variety"><em>Variety:</em> {entry.variety}</div>
                <div className="cell entry-form-print-sex"><em>Sex:</em> {entry.sex}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-jr-handler"><em>Name of Jr Handler:</em> {entry.jrHandlerName}</div>
                <div className="cell entry-form-print-jr-number"><em>Jr Handler Number:</em> {entry.jrHandlerNumber}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-dog-name"><em>Full Name of Dog:</em> {entry.akcName}</div>
                <div className="cell entry-form-print-call-name"><em>Call name:</em> {entry.callName}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-akc-number"><em>AKC Number:</em> {entry.akcRegistrationNum}</div>
                <div className="cell entry-form-print-date-of-birth"><em>Date of Birth:</em> {entry.dateOfBirth}</div>
                <div className="cell entry-form-print-place-of-birth"><em>Country of Birth:</em> {entry.countryOfBirth}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-breeder"><em>Breeder:</em> {entry.breeder}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-sire"><em>Sire:</em> {entry.sire}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-dam"><em>Dam:</em> {entry.dam}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-owner"><em>Actual Owner:</em> {entry.owners}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-address"><em>Address:</em> {entry.ownerAddress.street}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-city"><em>City:</em> {entry.ownerAddress.city}</div>
                <div className="cell entry-form-print-state"><em>State:</em> {entry.ownerAddress.state}</div>
                <div className="cell entry-form-print-zip"><em>Zip:</em> {entry.ownerAddress.zip}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-phone"><em>Phone:</em> {entry.ownerAddress.phone}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-email"><em>Email:</em> {entry.email}</div>
              </div>
              <div className="row">
                <div className="cell entry-form-print-handler"><em>Name of Agent/Handler:</em> {entry.handler}</div>
              </div>
            </div>
            <div id="entry-form-print-agreement-header">Agreement</div>
            <div id="entry-form-print-agreement-text">
              { agreementText }
            </div>
            { agreeToAgreementDate === '' ?
              <div className='entry-form-print-signature'>
                <span>Signature of owner or his duly authorized agent: </span>
                <span className='entry-form-print-signature-line'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
            :
              <div className='entry-form-print-signature'>
                <span>Signature of owner or his duly authorized agent:</span>
                <span className='entry-form-print-signature-line'>
                  electronically signed { moment(agreeToAgreementDate).tz('America/New_York').format('MM/DD/YYYY hh:mm a') } ET 
                </span>
              </div>
            }
            
          </div>
        )}
      </>
        : null
      }
    </Grid>
  )
}
