import { configureStore } from "@reduxjs/toolkit";
import registrantReducer from "./reducers/registrantSlice";
import eventConfigReducer from "./reducers/eventConfigSlice";
import eventDataReducer from "./reducers/eventDataSlice";
import entryFormsReducer from "./reducers/entryFormsSlice";
import userProfileSlice from "./reducers/userProfileSlice";

const store = configureStore({
  reducer: {
    registrant: registrantReducer,
    eventConfig: eventConfigReducer,
    eventData: eventDataReducer,
    entryForms: entryFormsReducer,
    userProfile: userProfileSlice,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
