import React from 'react';
import _ from 'lodash';
import './entryFormPrices.css';
import { useAppSelector } from '../../../redux/hooks';
import { EntryEventConfig } from '../../../models/EntryEventConfig';

export default function EntryFormPrices() {

  const { entryEvents } = useAppSelector((state: any) => state.eventConfig);

  const isPricesSameForAllEvents = () => {
    const prices = _.cloneDeep(entryEvents[0].prices);
    let isSame = true;
    entryEvents.forEach((entryEvent: EntryEventConfig, entryEventsIndex: number) => {
      if (!_.isEqual(prices, entryEvent.prices)) {
        isSame = false;
      }
    });
    return isSame;
  }

  const getEntryPriceText = (entryEventsIndex: number, pricesIndex: number) => {
    const nthText = [ 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Nineth' ];
    const length = entryEvents[entryEventsIndex].prices.length;

    if (pricesIndex === 0 && length === 1) {
      return `Entry Fee: $${entryEvents[entryEventsIndex].prices[pricesIndex]}`;
    }
    if (pricesIndex === 0) {
      return `First Entry: $${entryEvents[entryEventsIndex].prices[pricesIndex]}`;
    }
    if (pricesIndex === length - 1) {
      return `Each Add'l entry same dog, same day, same trial: $${ entryEvents[entryEventsIndex].prices[pricesIndex] }`
    }
    return `${nthText[pricesIndex]} Entry same dog, same day, same trial: $${entryEvents[entryEventsIndex].prices[pricesIndex]}`;
  }

  const getPaddedSpecialPrices = (classEntry: string, prices: string[]) => {
    let results: string[] = [];
    prices.forEach((price: string, i: number) => {
      if (i === 0) {
        results.push(`$${parseFloat(price).toFixed(2)} only class`);
      } else {
        results.push(`$${parseFloat(price).toFixed(2)} as add'l class`);
      }
    });
    if (classEntry === 'Brace' || classEntry.indexOf('Team') > -1) {
      return `${results.toString()} per team member`;
    }
    return results.toString();
  }

  return (
    <>
    { isPricesSameForAllEvents() ? 
      <>
        <h4>Entry Fees</h4>
        { entryEvents[0].prices.map((price: string, index: number) => 
          <div key={`entry-form-data-prices-${index}`} id={`entry-form-data-prices-${index}`}>{ getEntryPriceText(0, index) }</div>
        )}
        { _.get(entryEvents[0], 'juniorPrices', []).length > 0 ?
          <div id="entry-form-data-junior-prices-0">Junior Handler: ${ entryEvents[0].juniorPrices.toString() }</div>
          : null
        }
        { _.get(entryEvents[0], 'specialPrices', []).map((specialPrice: any, index: number) => 
          <div key={`entry-form-data-special-prices-${index}`} id="entry-form-data-special-prices-0">Special Prices: { specialPrice.class } : { getPaddedSpecialPrices(specialPrice.class, specialPrice.prices) }</div>
        )}
      </>
      :
      <>
        { entryEvents.map((entryEvent: EntryEventConfig, entryEventIndex: number) =>
          <React.Fragment key={`entryEvent-${entryEventIndex}`}>
            <h4>Entry Fees for {entryEvent.name} - {entryEvent.eventNum}</h4>
            { entryEvents[0].prices.map((price: string, index: number) => 
              <div key={`entry-form-data-prices-${entryEventIndex}-${index}`} id={`entry-form-data-prices-${entryEventIndex}-${index}`}>{ getEntryPriceText(entryEventIndex, index) }</div>
            )}
            { _.get(entryEvent, 'juniorPrices', []).length > 0 ?
              <div id={`entry-form-data-junior-prices-${entryEventIndex}`}>Junior Handler: ${ entryEvent.juniorPrices.toString() }</div>
              : null
            }
            { _.get(entryEvent, 'specialPrices', []).length > 0 ?
              <div id={`entry-form-data-special-prices-${entryEventIndex}`}>Special Prices: { entryEvent.specialPrices[0].class }: ${ entryEvent.specialPrices[0].prices.toString() }</div>
              : null
            }
          </React.Fragment>
        )}
      </>
    }
    </>
  )
}
