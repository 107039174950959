import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, FormControl, Grid, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import axios from 'axios';
import { setData } from '../../../redux/reducers/eventDataSlice';
import { setRegistrant } from '../../../redux/reducers/registrantSlice';
import { DataInfo } from '../../../models/DataInfo';
import { ReservationInfo } from '../../../models/ReservationInfo';
import { SessionConfig } from '../../../models/SessionConfig';
import './sessionInfo.css';
import _ from 'lodash';

interface ISessionInfo {
  eventId: string,
  saveReservation: (reservation: ReservationInfo) => void
  cancelReservation: (reservation: ReservationInfo) => void
}

const blankReservation: ReservationInfo = {
  registrantFirstName: '',
  registrantLastName: '',
  registrantEmail: '',
  dogName: '',
  jumpHt: '',
  breed: '',
  sessionIndex: 0,
  slotIndex: 0,
  sortValues: [],
  runs: [],      
  attendance:[],
  status: 'Available',
  edit: false
}

export default function SessionInfo(props: ISessionInfo) {

  const { firstName, lastName, email, isAdmin, isAuth } = useAppSelector((state: any) => state.registrant);
  const { sessions, activeSessionIndex, signupGranularity } = useAppSelector((state: any) => state.eventConfig);
  const { data } = useAppSelector((state: any) => state.eventData);
  const dispatch = useAppDispatch();

  const [ reservationChanged, setReservationChanged ] = useState(false);
  const [ reservationIsValid, setReservationIsValid ] = useState(false);
  const [ edittingIndex, setEdittingIndex ] = useState<number>(-1);
  const [ reservations, setReservations ] = useState<ReservationInfo[]>([]);
  const [ reservationSpecificFirstName, setReservationSpecificFirstName ] = useState('');
  const [ reservationSpecificLastName, setReservationSpecificLastName ] = useState('');
  const [ reservationSpecificEmail, setReservationSpecificEmail ] = useState('');
  const [ breeds, setBreeds ] = useState<string[]>(['']);
  const [ initialStateEdittedReservation, setInitialStateEdittedReservation ] = useState<ReservationInfo>(blankReservation);

  useEffect(() => {
    async function fetchBreeds() {
      try {
        const akcBreedsRsp = await axios.get(`/api/constants/akc-breeds`);
        const breedsRsp: any = _.get(akcBreedsRsp, 'data.breeds', []);
        const updatedBreeds: string[] = [ '' ];
        breedsRsp.forEach((breed: any) => {
          updatedBreeds.push(breed.name);
        });
        if (isMounted) setBreeds(updatedBreeds);
      } catch(err: any) {
        console.log('get constants akc-breeds err=' + JSON.stringify(err, null, 2));
      }
    }
    let isMounted = true;
    const updatedReservations: ReservationInfo[] = [];
    const currentSessions = _.cloneDeep(sessions);

    if (currentSessions.length > 0 && activeSessionIndex > -1) {
      fetchBreeds();
      for (let i = 0; i < _.get(currentSessions[activeSessionIndex], 'numberSlotsOffered', 0); i++) {
        const sortValues: number[] = [];
        const runs: number[] = [];
        currentSessions[activeSessionIndex].runs.forEach(() => {
          sortValues.push(-1);
          runs.push(0);
        });
        updatedReservations.push({
          dogName: '',
          jumpHt: '',
          breed: '',
          sessionIndex: activeSessionIndex,
          slotIndex: i,
          sortValues,
          runs,
          attendance: [],
          status: 'Available',
          edit: false,
          registrantFirstName: '',
          registrantLastName: '',
          registrantEmail: ''
        })
      }
      data.forEach((dataRecord: DataInfo) => {
        dataRecord.reservations.forEach((reservation: ReservationInfo) => {
          const sessionSlotIndex = _.findIndex(updatedReservations, val => val.sessionIndex === reservation.sessionIndex && val.slotIndex === reservation.slotIndex);
          const dogRegistrantIndex = _.findIndex(updatedReservations, val => val.dogName === reservation.dogName && val.jumpHt === reservation.jumpHt && val.registrantFirstName === dataRecord.registrant.firstName && val.registrantLastName === dataRecord.registrant.lastName);
          
          // for run-specific reservations, we want to avoid dups, so consolidate reservations from DB
          if (signupGranularity === 'run' && dogRegistrantIndex > -1) {
            reservation.sortValues.forEach((sortValue: number, sortValueIndex: number) => {
              if (sortValue > -1) {
                const updatedReservation = _.cloneDeep(updatedReservations[dogRegistrantIndex]);
                updatedReservation.runs[sortValueIndex] = updatedReservations[dogRegistrantIndex].runs[sortValueIndex] + 1;
                updatedReservations[dogRegistrantIndex] = updatedReservation;
              }
            });
          } else {
            updatedReservations[sessionSlotIndex] = { 
              ...reservation, 
              registrantId: dataRecord.registrant.registrantId,
              registrantFirstName: dataRecord.registrant.firstName,
              registrantLastName: dataRecord.registrant.lastName,
              registrantEmail: dataRecord.registrant.email,
              edit: false, 
              status: 'Reserved'
            };
          }
        });
      });
      setReservations(updatedReservations);
    }
    return () => { isMounted = false; }
  }, [activeSessionIndex, data, sessions, signupGranularity]);

  const initializeRuns = (updatedReservation: ReservationInfo) => {
    let isAllZero = true;
    _.get(updatedReservation, 'runs', []).forEach((run: number, runIndex: number) => {
      if (updatedReservation.runs[runIndex] > 0) {
        isAllZero = false;
      }
    });
    if (isAllZero) {
      updatedReservation.runs.forEach((run: number, runIndex: number) => {
        updatedReservation.runs[runIndex] = 1;
      });
    }
  }

  const handleClickSave = (index: number) => {
    let updatedReservations: ReservationInfo[] = _.cloneDeep(reservations);
    updatedReservations[index].edit = !updatedReservations[index].edit;
    if (updatedReservations[index].edit) {
      // transitioning into edit mode
      setInitialStateEdittedReservation({ ...updatedReservations[index], edit: false });
      updatedReservations[index].status = 'Save';
      initializeRuns(updatedReservations[index]);
      setEdittingIndex(index);
      setReservations(updatedReservations);
    } else {
      // transitioning out of edit mode
      if (reservationChanged) {
        if (reservationIsValid) {
          updatedReservations[index].status = 'Reserved';

          if (signupGranularity === 'run') {
            updatedReservations[index].runs.forEach((numRuns: number, runIndex: number) => {
              updatedReservations[index].sortValues[runIndex] = numRuns === 0 ? -1 : 99;
            });
          } else {
            const numRuns = sessions[activeSessionIndex].runs.length;
            for (let i = 0; i < numRuns; i++) {
              updatedReservations[index].sortValues[i] = 99;
              updatedReservations[index].runs[i] = 1;
            }
          }
          const updatedSessions: SessionConfig[] = _.cloneDeep(sessions);
          updatedSessions[activeSessionIndex].reservations = updatedReservations;
          const updatedData: DataInfo[] = _.cloneDeep(data);
          const dataIndex = _.findIndex(updatedData, val => val.registrant.firstName === updatedReservations[index].registrantFirstName && val.registrant.lastName === updatedReservations[index].registrantLastName && val.registrant.email === updatedReservations[index].registrantEmail);
          if (dataIndex === -1) {
            updatedData.push({
              registrant: {
                firstName: _.get(updatedReservations[index], 'registrantFirstName', ''),
                lastName: _.get(updatedReservations[index], 'registrantLastName', ''),
                email: _.get(updatedReservations[index], 'registrantEmail', ''),
                allowCookies: false
              },
              reservations: [
                {
                  ...updatedReservations[index],
                  status: undefined,
                  edit: undefined
                }
              ],
              eventId: '',
              classes: []
            })
          } else {
            updatedData[dataIndex].registrant.firstName = _.get(updatedReservations[index], 'registrantFirstName', '');
            updatedData[dataIndex].registrant.lastName = _.get(updatedReservations[index], 'registrantLastName', '');
            updatedData[dataIndex].registrant.email = _.get(updatedReservations[index], 'registrantEmail', '');
            updatedReservations[index].registrantId = updatedData[dataIndex].registrant.registrantId;
            const reservationIndex = _.findIndex(updatedData[dataIndex].reservations, val => val.sessionIndex === updatedReservations[index].sessionIndex && val.slotIndex === updatedReservations[index].slotIndex);
            if (reservationIndex === -1) {
              updatedData[dataIndex].reservations.push({
                ...updatedReservations[index],
                status: undefined,
                edit: undefined
              });
            } else {
              updatedData[dataIndex].reservations[reservationIndex] = {
                ...updatedReservations[index],
                status: undefined,
                edit: undefined
              };
            }
          }
          dispatch(setData(updatedData));
          if (
            !isAdmin &&
            firstName === '' &&
            lastName === '' &&
            email === '' &&
            _.get(updatedReservations[index], 'registrantFirstName', '') !== '' &&
            _.get(updatedReservations[index], 'registrantLastName', '') !== '' &&
            isEmailComplete(_.get(updatedReservations[index], 'registrantEmail', ''))
          ) {
            dispatch(setRegistrant({
              firstName: _.get(updatedReservations[index], 'registrantFirstName', ''),
              lastName: _.get(updatedReservations[index], 'registrantLastName', ''),
              email: _.get(updatedReservations[index], 'registrantEmail', '')
            }));
          }
          props.saveReservation(updatedReservations[index])
        } else {
          // since the reservation is invalid, revert it to its previous state
          updatedReservations[index] = _.cloneDeep(initialStateEdittedReservation);
          setInitialStateEdittedReservation(blankReservation);
          _.get(updatedReservations[index], 'sortValues', []).forEach((sortValue: number) => {
            sortValue = -1;
          });
        }
        setReservationChanged(false);
        setEdittingIndex(-1);
      } else {
        setEdittingIndex(-1);
        if (updatedReservations[index].dogName !== '') {
          updatedReservations[index].status = 'Reserved';
        } else {
          updatedReservations[index].status = 'Available';
        }
      }
    }
    setReservations(updatedReservations);
  }

  const reservationLabel = (index: number) => {
    const status = reservations[index].status;

    if (index === edittingIndex && reservationIsValid && reservationChanged) {
      return 'Save';
    }

    let label =  status === 'Reserved' ? 'Taken by someone else' :  status;

    if (signupGranularity !== 'slot') {
      label = 'Make a reservation';
    }
    
    if (reservations[index].dogName !== '') {
      if (
        isAdmin
      ) {
        label = `${reservations[index].registrantFirstName} ${reservations[index].registrantLastName} - ${reservations[index].dogName} (jumpHt: ${reservations[index].jumpHt})`;
      }
      if (
        firstName === reservations[index].registrantFirstName &&
        lastName === reservations[index].registrantLastName &&
        email === reservations[index].registrantEmail
      ) {
        label = `${reservations[index].dogName} (jumpHt: ${reservations[index].jumpHt})`;
      }
      if (
        reservationSpecificFirstName !== '' &&
        reservationSpecificFirstName === reservations[index].registrantFirstName &&
        reservationSpecificLastName !== '' &&
        reservationSpecificLastName === reservations[index].registrantLastName &&
        reservationSpecificEmail !== '' &&
        reservationSpecificEmail === reservations[index].registrantEmail
      ) {
        label = `${reservations[index].dogName} (jumpHt: ${reservations[index].jumpHt})`;
      }
      if (signupGranularity === 'run') {
        _.get(reservations[index], 'runs', []).forEach((numRuns: number, runIndex: number) => {
          if (numRuns > 0) {
            label += ` - ${sessions[0].runs[runIndex]} (${numRuns} run${ numRuns > 1 ? 's': ''})`;
          }
        });
      }
    }

    if (reservations[index].edit) {
      label = 'Close'
    }
    return label;
  }

  const handleClickCancel = (index: number) => {
    let updatedReservations = _.cloneDeep(reservations);

    // if no reservationId exists, then this has not preveiously been saved to the server/db, so no server trip is needed
    if (!updatedReservations[index].reservationId) {
      updatedReservations[index] = _.cloneDeep(initialStateEdittedReservation);
      setInitialStateEdittedReservation(blankReservation);
      setReservations(updatedReservations);
      setReservationIsValid(false);
      setEdittingIndex(-1);
      return;
    }
    const cancelledReservation = _.cloneDeep(updatedReservations[index]);

    props.cancelReservation(updatedReservations[index]);

    updatedReservations[index] = _.cloneDeep(blankReservation);
    setReservations(updatedReservations);
    setReservationIsValid(false);
    setEdittingIndex(-1);
    const updatedData: DataInfo[] = _.cloneDeep(data);
    const dataIndex = _.findIndex(updatedData, val => val.registrant.firstName === cancelledReservation.registrantFirstName && val.registrant.lastName === cancelledReservation.registrantLastName && val.registrant.email === cancelledReservation.registrantEmail);
    const reservationIndex = _.findIndex(updatedData[dataIndex].reservations, val => val.sessionIndex === cancelledReservation.sessionIndex && val.slotIndex === cancelledReservation.slotIndex);
    updatedData[dataIndex].reservations.splice(reservationIndex, 1);
    dispatch(setData(updatedData));
  }

  const isEmailComplete = (val: string) => {
    return (/^[A-Za-z0-9-_.]{1,64}@[A-Za-z0-9-_.]{1,256}(co|com|info|net|edu|gov|org)$/).test(val)
  }

  const isReservationValid = (updatedReservation: ReservationInfo) => {
    if (
      updatedReservation.dogName !== '' &&
      updatedReservation.jumpHt !== '' &&
      updatedReservation.registrantFirstName !== '' &&
      updatedReservation.registrantLastName !== '' &&
      isEmailComplete(_.get(updatedReservation, 'registrantEmail', ''))
    ) {
      if (signupGranularity !== 'run') {
        return true;
      }
      if (signupGranularity === 'run' && _.filter(updatedReservation.runs, (val: number) => val > 0).length > 0) {
        return true;
      }
    }
    return false;
  }


  const handleChangeBreed = (newValue: string, index: number) => {
    setReservationChanged(true);
    let updatedReservations = _.cloneDeep(reservations);
    updatedReservations[index].breed = newValue;
    setReservations(updatedReservations);
    setReservationIsValid(isReservationValid(updatedReservations[index]));
  }

  const handleChangeReservation = (event: any, index: number) => {
    setReservationChanged(true);
    let updatedReservations = _.cloneDeep(reservations);
    const id: string = _.get(event, 'target.id', '');
    const value: string = _.get(event, 'target.value', '');
    if (id === 'dog-name') {
      updatedReservations[index].dogName = value;
    }
    if (id === 'jump-height') {
      updatedReservations[index].jumpHt = value;
    }
    if ((/-[0-9]$/).test(id)) {
      const runsIndex = parseInt(id.split('-')[1]);
      updatedReservations[index].runs[runsIndex] = value ? parseInt(value) : 0;
    }
    if (id === 'slot-registrant-first-name') {
      updatedReservations[index].registrantFirstName = value;
      setReservationSpecificFirstName(value);
    }
    if (id === 'slot-registrant-last-name') {
      updatedReservations[index].registrantLastName = value;
      setReservationSpecificLastName(value);
    }
    if (id === 'slot-registrant-email') {
      updatedReservations[index].registrantEmail = value;
      if (isEmailComplete(value)) {
        setReservationSpecificEmail(value);
      }
    }
    if (
      updatedReservations[index].dogName !== '' &&
      updatedReservations[index].jumpHt !== ''
    ) {
      if (!isAdmin && firstName !== '' && lastName !== '') {
        updatedReservations[index].registrantFirstName = firstName;
        updatedReservations[index].registrantLastName = lastName;
        updatedReservations[index].registrantEmail = email;
      }
    }
    setReservations(updatedReservations);
    setReservationIsValid(isReservationValid(updatedReservations[index]));
  }

  const getReservationColor = (index: number) => {
    if (reservations[index].status === 'Save') {
      return 'black';
    }
    if (signupGranularity !== 'slot' && reservations[index].status === 'Available') {
      return 'white';
    }
    return reservations[index].status !== 'Available' ? 'green' : 'red';
  }

  const isReservationEditable = (index: number) => {
    const isReserved = reservations[index].status === 'Reserved';
    const isReservationOwner: boolean = 
      isReserved &&
      reservations[index].registrantFirstName === firstName &&
      reservations[index].registrantLastName === lastName &&
      reservations[index].registrantEmail === email;
    return isAdmin || isReservationOwner || !isReserved;
  }

  const showReservationSpecificName = () => {
    if (isAdmin) {
      return true;
    }
    if (firstName === '' || lastName === '' || email === '') {
      return true;
    }
    return false;
  }

  const getFullnessColor = () => {
    const totalReservations = _.filter(reservations, val => val.status === 'Reserved').length;
    const totalSlots = reservations.length;
    const pctFull = Math.round((totalReservations/totalSlots) * 100);
    if (pctFull > 90) {
      return 'red';
    }
    if (pctFull > 75) {
      return 'orange';
    }
    return 'green'
  }

  const getFullness = () => {
    let totalReservations: number = _.filter(reservations, val => val.status === 'Reserved').length;
    let totalSlots = reservations.length;

    if (signupGranularity === 'run') {
      totalReservations = 0;
      reservations.forEach((reservation: ReservationInfo) => {
        _.get(reservation, 'runs', []).forEach((numRuns: number) => {
          totalReservations += numRuns;
        });
      });
    }
    const pctFull = Math.round((totalReservations/totalSlots) * 100);
    return `${totalReservations} of ${totalSlots} slots are reserved (${pctFull}%)`
  }

  const showSlot = (index: number) => {
    if (signupGranularity === 'slot') {
      return true;
    }
    if (isAdmin && isAuth) {
      return true;
    }
    const firstAvailableIndex = _.findIndex(reservations, val => val.status !== 'Reserved');
    if (firstAvailableIndex > -1 && index === firstAvailableIndex) {
      return true;
    }
    if (firstName !== '' && lastName !== ''  && email !== '') {
      return firstName === reservations[index].registrantFirstName && lastName === reservations[index].registrantLastName && email === reservations[index].registrantEmail
    }
    if (reservationSpecificFirstName !== '' && reservationSpecificLastName !== '' && reservationSpecificEmail !== '') {
      return reservationSpecificFirstName === reservations[index].registrantFirstName && reservationSpecificLastName === reservations[index].registrantLastName && reservationSpecificEmail === reservations[index].registrantEmail
    }
    return false;
  }
  return (
    <Grid container alignItems="center" alignContent="center" justifyContent="center">
      <div id="session-info-session-fullness" style={{color: getFullnessColor() }} >{getFullness()}</div>
      { isAdmin ?
          <Grid item md={12}>
            <div id="session-info-reservation-header">Reservations</div>
          </Grid>
        : 
          <Grid item md={12}>
            <div id="session-info-reservation-header">
              Reservations { firstName ? `for ${firstName} ${lastName}` : '' } { email ? `(${email})` : '' }
              { firstName !== '' || email !== '' ?
                <Button 
                  id={'clear-name-email-button'}
                  color="secondary" 
                  style={{textTransform: 'none'}} 
                  onClick={() => dispatch(setRegistrant({ firstName: '', lastName: '', email: '' }))}>
                  Clear Name
                </Button>
                : null
              }
            </div>
          </Grid>
      }
      { reservations.map((slot: any, index: number) => 
        <React.Fragment key={'reservation-' + index}>
          { showSlot(index) ?
            <>
            <Grid item md={12} className="session-slot" key={index}>
              <Grid item md={12}>
                { signupGranularity === 'slot' || isAdmin ?
                  <span>Slot {index + 1}:</span>
                  : null
                }
                <Button 
                  id={'edit-button-session-' + slot.sessionIndex + '-slot-' + index}
                  className="session-slot-edit-button"
                  variant={ reservationLabel(index) === "Save" || reservationLabel(index) === "Make a reservation" ? "contained" : "text" }
                  style={{textTransform: 'none', color: getReservationColor(index) }} 
                  disabled={!isReservationEditable(index)}
                  onClick={() => handleClickSave(index)}>
                  {reservationLabel(index)}
                </Button>
                { isReservationValid(slot) ?
                  <Button 
                    id={'cancel-button-session-' + slot.sessionIndex + '-slot-' + index}
                    className="session-slot-cancel-button"
                    color="secondary" 
                    style={{textTransform: 'none'}} 
                    disabled={!isReservationEditable(index)}
                    onClick={() => handleClickCancel(index)}>
                    Cancel
                  </Button>
                  : null
                }
              </Grid>
              {slot.edit === true  ? 
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                  { showReservationSpecificName()  ?
                    <>
                      <Grid item md={3} xs={12} p={1}>
                        <FormControl fullWidth>
                          <TextField 
                            id="slot-registrant-first-name"
                            error={slot.registrantFirstName === ''}
                            helperText={slot.registrantFirstName === '' ? 'Required' : ''}
                            value={slot.registrantFirstName}
                            onChange={(e: any) => handleChangeReservation(e, index)}
                            label={ isAdmin ? "First Name" : "Your First Name" } />
                        </FormControl>
                      </Grid>
                      <Grid item md={4} xs={12} p={1}>
                        <FormControl fullWidth>
                          <TextField 
                            id="slot-registrant-last-name"
                            error={slot.registrantLastName === ''}
                            helperText={slot.registrantLastName === '' ? 'Required' : ''}
                            value={slot.registrantLastName}
                            onChange={(e: any) => handleChangeReservation(e, index)}
                            label={ isAdmin ? "Last Name" : "Your Last Name" } />
                        </FormControl>
                      </Grid>
                      <Grid item md={5} xs={12} p={1}>
                        <FormControl fullWidth>
                          <TextField 
                            id="slot-registrant-email"
                            error={slot.registrantEmail === '' || !isEmailComplete(slot.registrantEmail) }
                            helperText={slot.registrantEmail === '' ? 'Required' : !isEmailComplete(slot.registrantEmail) ? 'Invalid email' : ''}
                            value={slot.registrantEmail}
                            onChange={(e: any) => handleChangeReservation(e, index)}
                            label={ isAdmin ? "Email" : "Your Email" } />
                        </FormControl>
                      </Grid>
                    </>
                    : null
                  }
                  <Grid item md={3} xs={6} p={1}>
                    <FormControl fullWidth>
                      <TextField 
                        id="dog-name" 
                        error={slot.dogName === ''}
                        helperText={slot.dogName === '' ? 'Required' : ''}
                        className="margin-right-10" 
                        value={slot.dogName} onChange={(e: any) => handleChangeReservation(e, index)} 
                        label="Dog Name" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3} xs={6} p={1}>
                    <FormControl fullWidth>
                      <TextField 
                        id="jump-height" 
                        error={slot.jumpHt === ''}
                        helperText={slot.jumpHt === '' ? 'Required' : ''}
                        className="margin-right-10" 
                        value={slot.jumpHt} 
                        onChange={(e: any) => handleChangeReservation(e, index)} 
                        label="Dog Jump Ht" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12} p={1}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        id="breed"
                        options={breeds}
                        value={slot.breed}
                        onChange={(e: any, newValue: any) => handleChangeBreed(newValue.toString(), index)}
                        renderInput={(params: any) => <TextField {...params} label="Breed(optional)" />} />
                    </FormControl>
                  </Grid>
                    { signupGranularity === 'run' && activeSessionIndex > -1 ?
                      <>
                        { sessions[activeSessionIndex].runs.map((name: string, runsIndex: number) =>
                          <Grid item md={1} xs={6} p={1} key={`${name}-${runsIndex}`}>
                            <FormControl fullWidth>
                              <TextField 
                                id={`${name}-${runsIndex}`}
                                className="margin-right-10"
                                value={slot.runs[runsIndex]} 
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e: any) => handleChangeReservation(e, index)} 
                                label={`${name}`} />
                            </FormControl>
                          </Grid>
                        )}
                      </>
                      : null
                    }
                </Grid>
              : null
              }
            </Grid>
            </>
            : null
          }
        </React.Fragment>
      )}
    </Grid>
  )
}
