import React from 'react';
import { Button, Grid } from '@mui/material';
import { Done as DoneIcon } from '@mui/icons-material';
import './entryFormSteps.css';
import { setStepIndex, setEntries, setEdittingIndex, setSumEntryFee, setTotalServiceFee } from '../../../redux/reducers/entryFormsSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { EntryFormRecordData } from '../../../models/EntryFormRecordData';

export default function EntryFormSteps() {

  const { serviceFee, serviceFeeBasis } = useAppSelector((state: any) => state.eventConfig);
  const { edittingIndex, entries, stepIndex, agreeToAgreementDate } = useAppSelector((state: any) => state.entryForms);
  const dispatch = useAppDispatch();

  const steps = [
    'Step 1. Fill-in Entry form(s)',
    'Step 2. Review',
    'Step 3. Submit Payment',
    'Step 4. Done'
  ]
  const getColor = (myStep: number) => {
    return myStep < stepIndex ? 'green' : 'black'
  }

  const getFontWeight = (myStep: number) => {
    return myStep === stepIndex ? 'bold' : 'normal'
  }

  const handleChangeStep = (e: any, index: number) => {
    dispatch(setStepIndex(index));
    if (index > 0) {
      const updatedEntries: EntryFormRecordData[] = [];
      entries.forEach((entry: EntryFormRecordData) => {
        if (
          entry.entryFee !== 0 &&
          entry.akcName !== '' &&
          entry.callName !== '' &&
          entry.akcRegistrationNum !== '' &&
          entry.owners !== ''
        ) {
          updatedEntries.push(entry);
        }
      });
      if (index === 1) {
        let updatedSumEntryFee = 0;
        entries.forEach((entry: EntryFormRecordData) => {
          updatedSumEntryFee += entry.entryFee;
        });
        let updatedTotalServiceFee = 0;
        if (serviceFeeBasis === 'dog') {
          updatedTotalServiceFee = serviceFee * entries.length;
        }
        if (serviceFeeBasis === 'owner') {
          updatedTotalServiceFee = serviceFee;
        }
        dispatch(setSumEntryFee(updatedSumEntryFee));
        dispatch(setTotalServiceFee(updatedTotalServiceFee));
      }
      if (edittingIndex > updatedEntries.length - 1) {
        dispatch(setEdittingIndex(updatedEntries.length -1));
      }
      dispatch(setEntries(updatedEntries));
    }
  }
  return (
    <Grid container className='noprint'>
      <>
        { steps.map((stepText: string, index: number) => 
          <Grid item md={3} xs={12} p={2} key={'entry-form-step-' + index}>
            <Button 
              id={ `entry-form-step-${index + 1}`}
              style={{ textTransform: 'none', color: getColor(index), fontWeight: getFontWeight(index) }}
              variant="outlined"
              disabled={ (entries.length === 0 && index > 0) || (index === 2 && agreeToAgreementDate === '') }
              onClick={(e: any) => handleChangeStep(e, index) }>{ stepText }
            </Button>
            { stepIndex > index ? 
              <DoneIcon id={ 'entry-form-steps-done-' + (index + 1)} style={{ fontSize: 18 }} />
              : null
            }
          </Grid>
        )}
      </>
    </Grid>
  )
}
